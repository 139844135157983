// 
// @flow
import React, { Component } from 'react';
import flow from 'lodash/flow';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import {
  actions, getCourseById, getTopicsByCourse,
} from '../../ducks/courseBuilder';
import Topic from './Topic';




class Course extends Component {
  state = {
    hideArchived: true,
  };

  handleSave = (e) => {
    e.preventDefault();
    this.props.saveCourse(this.props.id, this.props.authenticityToken);
  };

  handleNewTopic = (e) => {
    e.preventDefault();
    // $FlowFixMe
    document.getElementById('scrollable').scrollTop = 0;
    this.props.newTopic(this.props.id);
  };

  toggleHideArchived = () => {
    this.setState({ hideArchived: !this.state.hideArchived });
  };

  render() {
    const {
      id, locked, topics,
    } = this.props;

    if (!(id > 0)) {
      return null;
    }

    const {
      busy, dirty, error, lastSaveSuccessful, title,
    } = this.props.course;

    const activeTopics = topics.filter((topic) => topic.archived === false);
    const topicsToDisplay = this.state.hideArchived ? activeTopics : topics;
    topicsToDisplay.forEach((t, i) => { t.position = i + 1; });

    return (
      <div className="sortable-lessons-list">
        <div className="row sortable-lessons-list__header" style={{ marginBottom: 18 }}>
          <div className="col-7">
            <h4>{title}</h4>
            <h6>Listing Lessons</h6>
          </div>
          <div className="col-5 text-right">
            <div className="mb-2">
              {!locked && <Button color="primary" size="xxs" onClick={this.handleSave} disabled={!dirty || busy}>Save</Button>}{' '}
              {!locked && <Button color="secondary" size="xxs" onClick={this.handleNewTopic} disabled={busy}>New chapter</Button>}
            </div>
            <div className="form-group">
              <label className='form-check-label'>
                <input type='checkbox' className='hide-archived mr-1' name='hide-archived' id='hide-archived' checked={this.state.hideArchived} onChange={this.toggleHideArchived} />
                Hide archived subchapters, chapters and lessons
              </label>
            </div>
          </div>
        </div>
        <div className="sortable-lessons-list__lessons">
          {lastSaveSuccessful === true && !dirty && <p className="text-success">The course was saved successfully</p>}
          {lastSaveSuccessful === false && !dirty && <p className="text-danger">{error}</p>}
          <div id="scrollable" className="dd">
            <ol className="dd-list" id="lessons" data-update-url="<%= sort_lessons_url %>">
              <Topic
                key={0}
                index={-1}
                courseId={id}
                fake
                locked={locked}
              />
              {
                topicsToDisplay && Object.keys(topicsToDisplay).map((key, i) => {
                  const topic = topicsToDisplay[key];
                  return (
                    <Topic
                      key={topic.id}
                      id={topic.id}
                      index={i}
                      archived={topic.archived}
                      position={topic.position}
                      title={topic.title}
                      difficulty={topic.difficulty}
                      courseId={id}
                      dirty={dirty}
                      locked={locked}
                      freeAccess={topic.freeAccess}
                      hideArchived={this.state.hideArchived}
                    />
                  );
                })
              }
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => (
  {
    course: getCourseById(state, ownProps.id),
    topics: getTopicsByCourse(state, ownProps.id),
    routes: state.routes,
  }
);
export default (flow(
  (connect(mapStateToProps, actions)),
)(Course));
