// 
// @flow
import courseBuilderReducers, { entities } from '../courseBuilder';

const routes = (state = {}) => state;

const reducers = {
  entities,
  routes,
  ...courseBuilderReducers,
};

export default reducers;
