// 
// @flow
/* eslint react/no-unused-prop-types: 0 */
import flow from 'lodash/flow';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';

import { MASTER_LESSON } from '../constants';
import { actions } from '../../ducks/courseBuilder';



const MasterLesson = (props) => {
  const icon = () => {
    const { category } = props;
    if (category === 'quiz') {
      return <FontAwesome name="question-circle" />;
    } else if (category === 'article') {
      return <FontAwesome name="paperclip" />;
    }
    return <FontAwesome name="television" />;
  };

  const {
    title, index, themeId, id, courseId,
  } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: MASTER_LESSON,
      drag() {},
      item: {
        id, courseId, themeId, index, type: MASTER_LESSON, masterLessonId: id, masterLesson: { ...props },
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.index],
  );
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: MASTER_LESSON,
      drop() {},
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [props.index],
  );
  const isTarget = canDrop && isOver;
  const opacity = isDragging ? 0.2 : 1;
  const underline = isTarget ? { borderBottom: '3px solid #5CB85C' } : {};

  const ref = useRef(null); // Initialize the reference
  drag(drop(ref));

  return (
    <li ref={ref} className="dd-item" style={{ opacity }}>
      <div className="dd-handle" style={{ ...underline }}>
        <span>
          {icon()}{' '}{title}
        </span>
      </div>
    </li>
  );
};

const mapStateToProps = state => ({ routes: state.routes });

export default (flow((connect(mapStateToProps, actions)),
)(MasterLesson));
