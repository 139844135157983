// 
// @flow
import { normalize } from 'normalizr';
import React from 'react';
import { Provider } from 'react-redux';

import * as schema from '../ducks/schema';
import configureStore from '../course-builder/configureStore';
import CourseBuilder from '../course-builder/CourseBuilder';


const CourseBuilderApp = (props) => {
  const {
    authenticityToken, targetCourse, routes,
    isAdmin,
  } = props;
  const courses = normalize(targetCourse, schema.course);
  const targetCourseId = courses.result;
  courses.entities.courses[targetCourseId].dirty = false;

  return (
    <Provider store={configureStore(
      {
        entities: courses.entities,
        routes,
        targetCourseId,
        isAdmin,
      })}
    >
      <CourseBuilder authenticityToken={authenticityToken} />
    </Provider>
  );
};

export default CourseBuilderApp;
