// 
// @flow
import React from 'react';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import {
  uniqBy, map, filter, find, isEmpty, isNull, values,
} from 'lodash';
import { format } from 'date-fns';
import SingleSearchableSelector from './SingleSearchableSelectorContainer';
import Pagination from './Pagination';
import ActivityAttributeChangeModal from './ActivityAttributeChangeModal';


class ActivityLogTable extends React.Component {
  state = {
    activityLogs: camelcaseKeysDeep(this.props.activityLogs),
    actors: this.props.actors,
    currentPage: 1,
    isActivityAttributeChangeModalOpen: false,
    sortDirection: 'desc',
    filtering: {
      subjectType: null,
      humanDescription: null,
      relatedId: null,
      actor: null,
    },
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleFiltering = (e) => {
    e.preventDefault();
    const {
      subjectType, humanDescription, relatedId, actor,
    } = this.state.filtering;

    const tempLogs = [...camelcaseKeysDeep(this.props.activityLogs)];

    // Define criteria, ignoring null or empty string values
    const criteria = [
      log => !subjectType || log.subjectType === subjectType,
      log => !humanDescription || log.humanDescription === humanDescription,
      log => !relatedId || log.relatedId === relatedId,
      log => !actor || log.actorId === actor,
    ];

    const filtered = tempLogs.filter(log => criteria.every(criterion => criterion(log)),
    );

    const activeFilters = !values(this.state.filtering).every((log) => isNull(log));

    if (activeFilters) {
      this.setState({ activityLogs: filtered });
    } else {
      this.setState({ activityLogs: camelcaseKeysDeep(this.props.activityLogs) });
    }
  };

  handleChange = (selectedOption, key) => {
    this.setState(prevState => ({
      filtering: {
        ...prevState.filtering,
        [key]: selectedOption?.value ?? selectedOption, // assuming 'title' is the key you want
      },
    }));
  };

  handleView = (e, log) => {
    e.preventDefault();
    this.setState({ isActivityAttributeChangeModalOpen: true, selectedLog: log });
  };

  handleSort = () => {
    const { sortDirection, activityLogs } = this.state;
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedLogs = [...activityLogs].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });

    this.setState({
      sortDirection: newDirection,
      activityLogs: sortedLogs,
    });
  };

  render() {
    const {
      activityLogs, actors, currentPage, isActivityAttributeChangeModalOpen, selectedLog, sortDirection,
    } = this.state;
    const actorsMerged = [
      ...actors.admins.map(([id, email]) => ({ id, email, type: 'admin' })),
      ...actors.staffMembers.map(([id, email]) => ({ id, email, type: 'staffMember' })),
    ];
    const logs = camelcaseKeysDeep(this.props.activityLogs);

    const subjectTypeCol = uniqBy(map(filter(logs, log => log.subjectType !== null), (log) => ({ id: log.subjectType, title: log.subjectType })), 'title');
    const humanDescCol = uniqBy(map(filter(logs, log => log.humanDescription !== null), (log) => ({ id: log.humanDescription, title: log.humanDescription })), 'title');
    const relatedIdCol = uniqBy(map(filter(logs, log => log.relatedId !== null), (log) => ({ id: log.relatedId, title: log.relatedId })), 'title');
    const actorCol = uniqBy(map(actorsMerged, (actor) => ({ id: actor.id, title: actor.email })), 'title');
    // pagination
    const itemsPerPage = 50;
    const totalPages = Math.ceil(activityLogs.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedLogs = activityLogs.slice(startIndex, startIndex + itemsPerPage);
    // end pagination

    const excludedDescriptions = [
      'Master lesson archived',
      'Master lesson copied',
      'Course resource copied',
      'Course resource deleted',
    ];

    return (
      <>
        <form onSubmit={(e) => this.handleFiltering(e)}>
          <div className="row">
            <div className="col">
              <div className="form-group">
                Type
                <SingleSearchableSelector
                  name="q[subject_type]"
                  collection={subjectTypeCol}
                  onHandleChange={(value) => this.handleChange(value, 'subjectType')}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                Event
                <SingleSearchableSelector
                  name="q[human_description]"
                  collection={humanDescCol}
                  onHandleChange={(value) => this.handleChange(value, 'humanDescription')}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                ID
                <SingleSearchableSelector
                  name="q[subject_id]"
                  collection={relatedIdCol}
                  onHandleChange={(value) => this.handleChange(value, 'relatedId')}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                Person
                <SingleSearchableSelector
                  name="q[actor]"
                  collection={actorCol}
                  onHandleChange={(value) => this.handleChange(value, 'actor')}
                />
              </div>
            </div>
          </div>
          <div className='form-actions'>
            <button type="submit" className="btn btn-primary btn-xxs">Filter</button>
          </div>
          <br />
        </form>

        <div className="ibox-content">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead className="thead-default">
                <tr>
                  <th onClick={this.handleSort} style={{ cursor: 'pointer' }}>
                    Timestamp {sortDirection === 'asc' ? '▲' : '▼'}
                  </th>
                  <th>Type</th>
                  <th>Event</th>
                  <th>ID</th>
                  <th>Person</th>
                  <th>Attributes changed</th>
                </tr>
              </thead>
              <tbody>
                { paginatedLogs.map(log => (
                  <tr key={log.id}>
                    <td> {format(new Date(log.createdAt), 'dd.MM.yyyy. HH:mm')} </td>
                    <td> {log.subjectType === 'Course' ? 'Settings' : log.subjectType} </td>
                    <td> {log.humanDescription} </td>
                    <td> {log.subjectType === 'Course' ? '-' : log.relatedId} </td>
                    <td> {find(actorsMerged, actor => actor.id === log.actorId).email + ' (' + log.actorType + ')'} </td>
                    {!isEmpty(log.attributeChanges) && !excludedDescriptions.includes(log.humanDescription) ? (
                      <td className="d-flex justify-content-center">
                        <button className="btn btn-primary btn-xs" onClick={(e) => this.handleView(e, log)}>
                          <i className="fa fa-eye mr-2" />
                          View
                        </button>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                ))}
              </tbody>
              <tfoot className="thead-default">
                <tr>
                  <th>Timestamp</th>
                  <th>Type</th>
                  <th>Event</th>
                  <th>ID</th>
                  <th>Person</th>
                  <th>Attributes changed</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        { isActivityAttributeChangeModalOpen && (
          <div className="modal-wrapper-activity-attribute-change">
            <ActivityAttributeChangeModal
              selectedLog={selectedLog}
              closeModal={() => this.setState({ isActivityAttributeChangeModalOpen: false })}
            />
          </div>
        )}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

export default (ActivityLogTable);
