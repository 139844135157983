// 
/* eslint-disable no-shadow */
// @flow
// Drag and drop react component for reordering lists
import { orderBy } from 'lodash';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



class ListDragNDrop extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      list: props.itemsList,
    };
  }

  componentDidMount() {
    const orderdedList = orderBy(this.state.list, 'order', 'asc');
    this.setState({ list: orderdedList });
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  goToLastPlace(index) {
    const list = this.reorder(
      this.state.list,
      index,
      this.state.list.length - 1,
    );
    this.setState({
      list,
    });
    this.props.onChange(list);
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list = this.reorder(
      this.state.list,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      list,
    });
    this.props.onChange(list);
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'whitesmoke',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    transition: 'background-color 0.2s ease',
  });

  render() {
    const { list } = this.state;
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                { list.map((item, index) => (
                  <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="d-flex list-row justify-content-between"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        key={`${item.id}`}
                        index={index}
                      >
                        <div className="d-flex">
                          <div className="position">{index + 1}.</div>
                          <div className="item-name">{item.name}</div>
                        </div>
                        {index + 1 !== this.state.list.length && <div className="last-place" onClick={() => this.goToLastPlace(index)}>Move to bottom</div>}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default (ListDragNDrop);
