// 
// Entry point for the build script in your package.json
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as startupComponents from './react';

console.log('React esbuild');

const mount = (components) => {
  // Explicitly reference URL.createObjectURL to ensure it exists and is initialized
  // Required after switching to Esbuild
  URL.createObjectURL;
  document.addEventListener('DOMContentLoaded', () => {
    const mountPoints = document.querySelectorAll('[data-react-component-name]');
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint;
      const componentName = dataset.reactComponentName;
      if (componentName) {
        const Component = components[componentName];
        if (Component) {
          const props = JSON.parse(dataset.props);
          const root = ReactDOM.createRoot(mountPoint);
          root.render(<Component {...props} />);
        }
      }
    });
  });
};

mount(startupComponents);
