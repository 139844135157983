// 
// @flow
import {
  filter, isEmpty, merge,
  sortBy, toInteger,
} from 'lodash';

import { selectors as themesSelectors } from './themes';
import { selectors as coursesSelectors } from './courses';

export const types = {
  EXPAND_TOPIC: 'TOPICS/EXPAND_TOPIC',
};

export default (state = {}, action) => {
  if (action.entities && action.entities.topics) {
    return merge({}, state, action.entities.topics);
  }

  return state;
};

export const actions = {
  expandTopic: (id) => ({ type: types.EXPAND_TOPIC, id }),
};

// Simple selectors
export const selectors = {
  getTopic: (state, id) => state.entities.topics[id],
  getTopics: (state, courseId, urlSlug = null) => {
    if (urlSlug) {
      const course = coursesSelectors.getCourseFromUrlSlug(state, urlSlug);
      courseId = course && course.id;
    }
    return (sortBy(filter(state.entities.topics, { courseId: toInteger(courseId) }), topic => topic.position));
  },
  getNextTopic: (state, currentTopicId) => {
    const currentTopic = selectors.getTopic(state, currentTopicId);
    if (currentTopic === undefined) return undefined;
    const topics = selectors.getTopics(state, currentTopic.courseId);
    if (isEmpty(topics)) return undefined;
    const currentTopicIndex = topics.findIndex(topic => topic.id === currentTopicId);
    if (currentTopicIndex < 0) return undefined;
    return topics[currentTopicIndex + 1];
  },
  getTopicByLesson: (state, id) => {
    const theme = themesSelectors.getThemeByLesson(state, id);
    if (theme === undefined) return undefined;
    return selectors.getTopic(state, theme.topicId);
  },
  getTopicsByLesson: (state, id) => {
    const topic = selectors.getTopicByLesson(state, id);
    if (topic === undefined) return undefined;
    return selectors.getTopics(state, topic.courseId);
  },
  getTopicByTheme: (state, themeId) => {
    const theme = themesSelectors.getTheme(state, themeId);
    if (theme === undefined) return undefined;
    return selectors.getTopic(state, theme.topicId);
  },
};
