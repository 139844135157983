// 
// @flow
import React from 'react';
import { Button } from 'reactstrap';


class ThreadModal extends React.Component {
  componentDidMount() {
    const html = document.querySelector('html');
    if (html) {
      html.classList.add('modal-open');
    }
  }

  componentWillUnmount() {
    const html = document.querySelector('html');
    if (html) {
      html.classList.remove('modal-open');
    }
  }

  render() {
    const { onCloseModal } = this.props;

    return (
      <div className="thread-modal-wrapper mini-modal-wrapper">
        <div className="modal-box quit-new-modal">
          <div className="thread-comment-modal-wrapper">
            <div className="text-center">
              <h4>Obs! Ved en feil ble ikke innlegget publisert.</h4>
              <h4>Vennligst prøv igjen.<span aria-hidden="true" role="img">😀</span></h4>
            </div>
            <div className="actions-wrapper">
              <Button
                block
                className="btn cancel p-3 px-4"
                onClick={onCloseModal}
                outline
              >
                Den er god!
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (ThreadModal);
