// 
// @flow
import React from 'react';
import { values, orderBy } from 'lodash';
import FontAwesome from 'react-fontawesome';



class CourseDiscussionsCoursesList extends React.Component {
  state = {
    sortTableBy: 'default',
    sortOrder: 'asc',
  };

  sortTable = (sort) => {
    const { sortOrder } = this.state;

    if (sort && sortOrder === 'desc') {
      this.setState({ sortTableBy: sort, sortOrder: 'asc' });
    } else if (sort && sortOrder === 'asc') {
      this.setState({ sortTableBy: sort, sortOrder: 'desc' });
    }
  };

  render() {
    const {
      courses, onSelectCourse, getCourseQuestions,
    } = this.props;
    const { sortTableBy, sortOrder } = this.state;

    let coursesCollection = courses && values(courses);
    if (coursesCollection) {
      // eslint-disable-next-line no-return-assign
      coursesCollection.map(course => (
        // eslint-disable-next-line no-sequences
        course.numberOfQuestionsWithoutComments = getCourseQuestions(course.id, 'getCommentNumberInfo'),
        course.unansweredByTeacherQuestionsBumber = getCourseQuestions(course.id, 'getLastAnswerStatus'),
        course.latestCommentDateString = getCourseQuestions(course.id, 'getLatestCommentDate'),
        course.latestCommentFromNowInMinutes = getCourseQuestions(course.id, 'getLatestCommentFromNowInMinutes')
      ));
      // Default sort puts bolded items first (alphabetically) and then the rest (alphabetically)
      if (sortTableBy === 'default') {
        const boldedItems = coursesCollection.filter(course => getCourseQuestions(course.id, 'getCourseStatus'));
        const nonBoldedItems = coursesCollection.filter(course => !getCourseQuestions(course.id, 'getCourseStatus'));
        coursesCollection = [...orderBy(values(boldedItems), 'title', 'asc'), ...orderBy(values(nonBoldedItems), 'title', 'asc')];
      } else {
        coursesCollection = courses && orderBy(values(courses), sortTableBy, sortOrder);
      }
      if (sortTableBy === 'latestCommentDate') {
        coursesCollection = courses && orderBy(values(courses), [
          course => course.latestCommentFromNowInMinutes,
          course => course.latestCommentFromNowInMinutes === null,
        ], [sortOrder, sortOrder]);
      }
    }

    return (
      <>
        <div className="discussions-header">
          <div className="title">Forum</div>
        </div>

        <div className="discussions-table">
          <table>
            <thead>
              <tr>
                <th className="course-title" onClick={() => this.sortTable('title')}>Kurs  <FontAwesome name="sort" /></th>
                <th onClick={() => this.sortTable('numberOfQuestionsWithoutComments')}>Nye poster <FontAwesome name="sort" /></th>
                <th onClick={() => this.sortTable('unansweredByTeacherQuestionsBumber')}>Ubesvart kommentar  <FontAwesome name="sort" /></th>
                <th onClick={() => this.sortTable('latestCommentDate')}>Siste aktivitet  <FontAwesome name="sort" /></th>
              </tr>
            </thead>
            <tbody>
              {coursesCollection.map(course => (
                <tr
                  key={course.id}
                  onClick={() => onSelectCourse(course)}
                >
                  <td className="course-title">{getCourseQuestions(course.id, 'getCourseStatus') ? <b>{course.title}</b> : course.title}</td>
                  <td>{course.numberOfQuestionsWithoutComments}</td>
                  <td>{course.unansweredByTeacherQuestionsBumber}</td>
                  <td>{course.latestCommentDateString}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default (CourseDiscussionsCoursesList);
