// 
// @flow
import React from 'react';
import { Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';


const ToggleAccessButton = (props) => {
  const { freeAccess, onToggle } = props;

  return (
    <Button size="xxs" className={`toggle-acces-btn ${freeAccess ? 'free' : ''}`} onClick={onToggle}>
      Free {freeAccess ? <FontAwesome name="check" /> : <FontAwesome name="close" />}
    </Button>
  );
};

export default ToggleAccessButton;
