// 
// @flow
import {
  merge,
} from 'lodash';
import { selectors as topicsSelectors } from './topics';

export const types = {
  CONTINUE_COURSE: 'COURSES/CONTINUE_COURSE',
  CONTINUE_COURSE_FAILURE: 'COURSES/CONTINUE_COURSE_FAILURE',
  CONTINUE_COURSE_SUCCESS: 'COURSES/CONTINUE_COURSE_SUCCESS',
  EXPAND_COURSE: 'COURSES/EXPAND_COURSE',
  GET_COURSE: 'COURSES/GET_COURSE',
  GET_COURSE_FAILURE: 'COURSES/GET_COURSE_FAILURE',
  GET_COURSE_FOR_EXPLORE_FAILURE: 'COURSES/GET_COURSE_FOR_EXPLORE_FAILURE',
  GET_COURSE_SUCCESS: 'COURSES/GET_COURSE_SUCCESS',
  GET_COURSE_FOR_EXPLORE_SUCCESS: 'COURSES/GET_COURSE_FOR_EXPLORE_SUCCESS',
  GET_COURSE_FROM_LESSON_ID: 'COURSES/GET_COURSE_FROM_LESSON_ID',
  GET_COURSES: 'COURSES/GET_COURSES',
  GET_COURSES_FAILURE: 'COURSES/GET_COURSES_FAILURE',
  GET_COURSES_SUCCESS: 'COURSES/GET_COURSES_SUCCESS',
  GET_COURSES_SLIM: 'COURSES/GET_COURSES_SLIM',
  GET_COURSES_SLIM_FAILURE: 'COURSES/GET_COURSES_SLIM_FAILURE',
  GET_COURSES_SLIM_SUCCESS: 'COURSES/GET_COURSES_SLIM_SUCCESS',
  SORT_COURSES: 'COURSES/SORT_COURSES',
  TOGGLE_COURSE_FAVOURITE: 'COURSES/TOGGLE_COURSE_FAVOURITE',
  TOGGLE_COURSE_FAVOURITE_FAILURE: 'COURSES/TOGGLE_COURSE_FAVOURITE_FAILURE',
  TOGGLE_COURSE_FAVOURITE_SUCCESS: 'COURSES/TOGGLE_COURSE_FAVOURITE_SUCCESS',
  RESET_COURSE_PROGRESS: 'COURSES/RESET_COURSE_PROGRESS',
  RESET_COURSE_PROGRESS_SUCCESS: 'COURSES/RESET_COURSE_PROGRESS_SUCCESS',
  RESET_COURSE_PROGRESS_FAILURE: 'COURSES/RESET_COURSE_PROGRESS_FAILURE',
  SET_COURSE_PROGRESS_TO_DONE: 'COURSES/SET_COURSE_PROGRESS_TO_DONE',
  SET_COURSE_PROGRESS_TO_DONE_SUCCESS: 'COURSES/SET_COURSE_PROGRESS_TO_DONE_SUCCESS',
  SET_COURSE_PROGRESS_TO_DONE_FAILURE: 'COURSES/SET_COURSE_PROGRESS_TO_DONE_FAILURE',
};

export default (state = {}, action) => {
  if (action.entities && action.entities.courses) {
    return merge({}, state, action.entities.courses);
  }

  switch (action.type) {
    case types.TOGGLE_COURSE_FAVOURITE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          favourite: !state[action.id].favourite,
        },
      };

    default:
      return state;
  }
};

export const actions = {
  continueCourse: (id) => ({ type: types.CONTINUE_COURSE, id }),
  expandCourse: (id, forExplore = false) => ({ type: types.EXPAND_COURSE, id, forExplore }),
  getCourseByLesson: (id) => ({ type: types.GET_COURSE_FROM_LESSON_ID, id }),
  getCourses: (forExplore = false) => ({ type: types.GET_COURSES, forExplore }),
  getCoursesSlim: () => ({ type: types.GET_COURSES_SLIM }),
  getCoursesFailure: (error) => ({ type: types.GET_COURSES_FAILURE, error }),
  getCoursesSuccess: (entities) => ({ type: types.GET_COURSES_SUCCESS, entities }),
  sortCourses: (attribute) => ({ type: types.SORT_COURSES, attribute }),
  toggleCourseFavourite: (id, favourite) => ({ type: types.TOGGLE_COURSE_FAVOURITE, id, favourite }),
  resetCourseProgress: (id) => ({ type: types.RESET_COURSE_PROGRESS, id }),
  setCourseProgressToDone: (id) => ({ type: types.SET_COURSE_PROGRESS_TO_DONE, id }),
};

const getCourses = (state) => state.entities.courses;
const getSortingAscending = (state) => state.sorting.courses.ascending;
const getSortingAttribute = (state) => state.sorting.courses.attribute;

// Simple selectors
export const selectors = {
  getCourse: (state, id, isExplore = false) => {
    if (isExplore) {
      const course = selectors.getCourseFromUrlSlug(state, id);
      id = course && course.id;
    }
    return state.entities.courses[id];
  },
  getCourses,
  getSortingAscending,
  getSortingAttribute,
  getCourseByLesson: (state, rawLessonId) => {
    const lessonId = parseInt(rawLessonId, 10);
    const topic = topicsSelectors.getTopicByLesson(state, lessonId);
    if (topic === undefined) return undefined;
    return selectors.getCourse(state, topic.courseId);
  },
  getCourseByTheme: (state, rawThemeId) => {
    const themeId = parseInt(rawThemeId, 10);
    const topic = topicsSelectors.getTopicByTheme(state, themeId);
    if (topic === undefined) return undefined;
    return selectors.getCourse(state, topic.courseId);
  },
  getCourseByTopic: (state, rawTopicId) => {
    const topicId = parseInt(rawTopicId, 10);
    const topic = topicsSelectors.getTopic(state, topicId);
    if (topic === undefined) return undefined;
    return selectors.getCourse(state, topic.courseId);
  },
};
