// 
// @flow
// import { getApp } from 'firebase/app';
// import {
//   getDatabase, ref, query, orderByKey, limitToFirst, onValue, update,
// } from 'firebase/database';

// const getPrefix = () => {
//   // const firebaseApp = getApp();
//   // return firebaseApp.options.firebasePrefix;
// 
//   const firebaseApp = firebase.app(); // Using the global firebase object
//   return firebaseApp.options.firebasePrefix;
// };

export const subscribeToLastChange = (callback) => {
  if (process.env.IS_FIREBASE_ACTIVE === 'true') {
    // const firebaseApp = getApp();
    // const db = getDatabase(firebaseApp);
    // const dbRef = ref(db, `${getPrefix()}/last_change`);
    // onValue(dbRef, data => {
    //   callback(data.val());
    // });
  
    const firebaseApp = firebase.app(); // Using the global firebase object
    const db = firebase.database(); // Get the database service from the global firebase object
    
    const getPrefix = () => {
      // const firebaseApp = getApp();
      // return firebaseApp.options.firebasePrefix;
      
      const firebaseApp = firebase.app(); // Using the global firebase object
      return firebaseApp.options.firebasePrefix;
    };
    
    const dbRef = firebase.database().ref(`${getPrefix()}/last_change`); // Using the global firebase database service
    
    firebase.database().ref(dbRef).on('value', snapshot => {
      callback(snapshot.val());
    });
  }
};
