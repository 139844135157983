// 
// @flow

import React from 'react';
import {
  Input,
} from 'reactstrap';
import searchIcon from '../../../../../assets/images/icons/search-icon-new.svg';
import closeIcon from '../../../../../assets/images/icons/close-icon.svg';


const SearchInput = ({
  prependIcon = searchIcon, appendIcon, value, onChange, hidePrependIcon = false,
  resetSearchFilter, isSearching, placeholder, onFocus, onBlur, className = '',
}) => (
  <div className='input-search-wrapper'>
    { !hidePrependIcon && <img src={prependIcon} alt="" className='prepend-icon' /> }
    <Input
      name="search"
      onChange={(e) => onChange(e)}
      onFocus={() => onFocus()}
      onBlur={() => onBlur()}
      placeholder={placeholder}
      value={value}
      className={`input-field ${className}`}
    />
    {isSearching && (
      <div className="clear-icon-container" onClick={resetSearchFilter}>
        <img
          src={closeIcon}
          className="clear-icon"
          alt=""
        />
      </div>
    )}
    { appendIcon && <img src={appendIcon} alt="" className='appendIcon' /> }
  </div>
);

export default ((SearchInput));
