// 
// @flow
import {
  intervalToDuration, formatDuration, parseISO, formatDistance, addHours, getMinutes, getHours, getSeconds,
} from 'date-fns';
import { nb } from 'date-fns/esm/locale';
import { format, utcToZonedTime } from 'date-fns-tz';

const formatDistanceLocale = { xMinutes: '{{count}}m', xHours: '{{count}}t' };
const formatDistanceLocaleShort = { xSeconds: '{{count}}s', xMinutes: '{{count}}min' };
const shortEnLocale = { formatDistance: (token, count) => formatDistanceLocale[token].replace('{{count}}', count) };
const shortEnLocaleShort = { formatDistance: (token, count) => formatDistanceLocaleShort[token].replace('{{count}}', count) };
const formatHours = { format: ['hours', 'minutes'], locale: shortEnLocale };
const formatMinutes = { format: ['minutes', 'seconds'], locale: shortEnLocaleShort };

// FORMAT DURATION TO CUSTOM FORMAT
export const formatDurationTime = (time) => {
  const duration = intervalToDuration({ start: 0, end: time * 1000 });
  return formatDuration(duration, time > 3599 ? formatHours : formatMinutes);
};

export const formatInTimeZone = (date, fmt, tz = 'UTC') => {
  const parsedDate = parseISO(date);
  return format(utcToZonedTime(parsedDate, tz), fmt, { timeZone: tz });
};

// $FlowFixMe
export const timeFromNow = (date) => formatDistance(parseISO(date, 'dd.MM.yyyy HH:mm:ss'), new Date(), { locale: nb });

export const convertToDuration = (secondsAmount) => {
  if (secondsAmount) {
    const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time);

    const SECONDS_TO_MILLISECONDS_COEFF = 1000;
    const MINUTES_IN_HOUR = 60;

    const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

    const date = new Date(milliseconds);
    const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
    const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

    const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
    const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
    const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

    const hoursOutput = hours !== '00' ? `${hours}:` : '';

    return `${hoursOutput}${minutes}:${seconds}`;
  }
  return null;
};
