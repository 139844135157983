// 
// @flow
import React, { useState } from 'react';
import axios from 'axios';
import { Alert } from 'reactstrap';
import ListDragNDrop from '../scenes/shared/ListDragNDrop';


const UniversitiesReorderList = ({ universities }) => {
  const [universitiesList, setUniversitiesList] = useState(universities);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleListSave = (e) => {
    e.preventDefault();
    const data = universitiesList.map((uni, index) => (
      { order: index, name: uni.name, id: uni.id }
    ));
    axios.post('/universities/save_order.json', { data }).then(
      () => {
        setMessage('Saved');
        window.scrollTo(0, 0);
        // $FlowFixMe
        setTimeout(() => {
          setMessage(null);
        }, 3000);
      },
    ).catch((event) => {
      setError('Something went wrong');
      throw event.response;
      // $FlowFixMe
      setTimeout(() => {
        setError(null);
      }, 3000);
    });
  };

  return (
    <>
      {error && <Alert className="alert-message" color="danger">{error}</Alert>}
      {message && <Alert className="alert-message" color="success">{message}</Alert>}
      <form method="post" className="d-flex flex-column align-items-center">
        <input type="hidden" value={universitiesList} />
        <div className="d-flex justify-content-between align-items-center mb-2" style={{ width: '600px' }}>
          <h5>Universities list</h5>
          <button className="btn btn-xs btn-primary" onClick={handleListSave}>Save list</button>
        </div>
        <ListDragNDrop itemsList={universities} onChange={(value) => setUniversitiesList(value)} />
        <div className="d-flex justify-content-between align-items-center mt-2" style={{ width: '600px' }}>
          <a href="/universities" className="btn btn-xs btn-secondary">Back</a>
          <button className="btn btn-xs btn-primary" onClick={handleListSave}>Save list</button>
        </div>
      </form>
    </>
  );
};

export default (UniversitiesReorderList);
