// 
// @flow
import { includes } from 'lodash';


export const types = {
  HIDE_ERROR: 'ERRORS/HIDE_ERROR',
};

const defaultState = { error: false, text: '', type: '' };

export const actions = {
  hideError: () => ({ type: types.HIDE_ERROR }),
};

export default (state = defaultState, action) => {
  if (includes(action.type, 'FAILURE')) {
    return {
      error: true,
      text: action.error,
      type: action.type,
    };
  } else if (includes(action.type, 'SUCCESS') || action.type === types.HIDE_ERROR) {
    return defaultState;
  }

  return state;
};
