// 
// @flow
import { keys, includes, find } from 'lodash';


export const types = {
  CHANGE_PAGE: '@@router/LOCATION_CHANGE',
};

const defaultState = { currentPage: '', feedbackPages: {} };

export const actions = {
  changePage: () => ({ type: types.CHANGE_PAGE }),
};

// Simple selectors
export const selectors = {
  getPageKey: (state) => {
    const { currentPage } = state.pages;
    if (currentPage) {
      const exactRoutes = {
        app: 'courses',
        courses: 'courseOverview',
        thank_you: 'thankYou',
        course_completed: 'courseCompleted',
        profile: 'studentProfile',
        invites: 'studentInvites',
        resources: 'resources',
      };

      const exactRouteKey = find(keys(exactRoutes), r => includes(currentPage, r));

      if (exactRouteKey) return exactRoutes[exactRouteKey];

      if (includes(currentPage, 'lessons')) return 'lesson';
    }

    return 'courses';
  },
  getFeedbackPages: (state) => state.pages.feedbackPages,
};

export default (state = defaultState, action) => {
  if (action.type === types.CHANGE_PAGE) {
    return { ...state, currentPage: action.payload.location.pathname };
  }

  return state;
};
