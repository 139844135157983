// 
// @flow
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import CourseDiscussions from './CourseDiscussions';
import { selectors as questionsSelectors } from '../../ducks/forumQuestions';
import { selectors as coursesSelectors } from '../../ducks/courses';
import forumActions from '../../redux/discussions/actions';
import { actions as errorActions } from '../../ducks/errors';
import ErrorPostModal from './modals/ErrorPostModal';



class TeacherDiscussionsView extends Component {
  state = {
    selectedCourse: null,
    errorPostModalOpened: false,
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (prevProps.error.error !== error.error && error.error) {
      this.handlePostError();
    }
  }

  handleSelectCourse = (course) => {
    this.setState({
      selectedCourse: course,
    });
  };

  handlePostError = () => {
    this.setState({ errorPostModalOpened: true });
  };

  goToCourseForumOverview = () => {
    this.setState({ selectedCourse: null });
  };

  render() {
    const {
      selectedCourse, errorPostModalOpened,
    } = this.state;
    const {
      teacher, courses, error,
      hideError,
    } = this.props;

    return (
      <>
        <CourseDiscussions
          teacher={teacher}
          selectedCourse={selectedCourse}
          error={error}
          goToCourseForumOverview={this.goToCourseForumOverview}
          onSelectCourse={this.handleSelectCourse}
          courses={courses}
        />

        <div>
          {errorPostModalOpened && (
            <ErrorPostModal
              onCloseModal={() => { hideError(); this.setState({ errorPostModalOpened: false }); }}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  teacher: ownProps.teacher,
  questions: questionsSelectors.getForumQuestions(state),
  courses: coursesSelectors.getCourses(state),
  error: state.errors,
});

export default (connect(
  mapStateToProps, { ...forumActions, ...errorActions },
)(TeacherDiscussionsView));
