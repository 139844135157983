// 
// @flow
import React, { Component } from 'react';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { normalize } from 'normalizr';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
// import { initializeApp, getApps, getApp } from 'firebase/app';
// import { getAuth, signInAnonymously } from 'firebase/auth';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import classNames from 'classnames';
import * as schema from '../ducks/schema';
import TeacherDiscussionsView from '../scenes/discussions/TeacherDiscussionsView';
import configureStore from '../redux/discussions/configureStore';


class TeacherDiscussionsApp extends Component {
  state = {
    isFirebaseReady: false,
  };

  componentDidMount() {
    this.initializeFirebase();
  }

  initializeFirebase = () => {
    try {
      if (!firebase.apps.length) { // This checks if Firebase has not been initialized.
        // Your Firebase configuration
        const firebaseConfig = {
          apiKey: 'AIzaSyD4tiXuVtHnWgjCPe_Inx2j9nNoKJmh0es',
          authDomain: 'enkeleksamen-dev.firebaseapp.com',
          databaseURL: 'https://enkeleksamen-dev.firebaseio.com',
          projectId: 'enkeleksamen-dev',
          firebasePrefix: 'ante-usersanteworkenkeleksamen',
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
      }
      this.setState({ isFirebaseReady: true });
    } catch (error) {
      console.log('Firebase initialization failed: ', error);
      setTimeout(this.initializeFirebase, 1000); // Retry after 1 second
    }
  };

  render() {
    const {
      teacher, teachers, courses,
      questions, firebaseConfig,
      isAdmin,
    } = this.props;
    console.log('isFirebaseReady', this.state.isFirebaseReady);
    const initialState = {
      courses: normalize(courses, schema.courseList).entities.courses,
      forumQuestions: normalize(camelcaseKeysDeep(questions), schema.forumQuestions).entities.forumQuestions,
    };
    const { createReduxHistory } = createReduxHistoryContext({
      history: createBrowserHistory(),
    });
    const forumEnabled = process.env.IS_FORUM_ACTIVE === 'true';
    const firebaseEnabled = process.env.IS_FIREBASE_ACTIVE === 'true';

    if (firebaseEnabled && this.state.isFirebaseReady) {
      // const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApp();
      // const firebaseAuth = getAuth(firebaseApp);
      // signInAnonymously(firebaseAuth);

      console.log(firebase);
      const firebaseAuth = firebase.auth(); // Get auth service from the global firebase object
      console.log(firebaseAuth);
      firebaseAuth.signInAnonymously()
        .then(() => {
          console.log('Firebase connected');
          // Handle successful anonymous sign-in if needed
        })
        .catch((error) => {
          console.log(error);
        });
    }

    const store = configureStore(
      initialState,
    );

    const history = createReduxHistory(store);

    return (
      <Provider store={store}>
        <Router history={history}>
          <Routes>
            <Route
              path="/conversations"
              element={(
                <div className={classNames('teacher-discussions w-100', { isTeacher: !isAdmin })}>
                  {forumEnabled && (
                  <TeacherDiscussionsView
                    teacher={camelcaseKeysDeep(teacher)}
                    teachers={camelcaseKeysDeep(teachers)}
                  />
                  )}
                </div>
              )}
            />
          </Routes>
        </Router>
      </Provider>
    );
  }
}

export default (TeacherDiscussionsApp);
