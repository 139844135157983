// 
// @flow
/* eslint no-console: 0 */
/**
 * Should only contain the `rootSaga` and any required imports.
 */
import axios from 'axios';
import { normalize } from 'normalizr';
import {
  all, call, put, select,
  takeEvery,
} from 'redux-saga/effects';
import camelcaseKeysDeep from 'camelcase-keys-deep';

import { LOCATION_CHANGE } from 'redux-first-history';
import keysToSnakeCase from '../utilities/keysToSnakeCase';
import { getTopicsByCourse, getThemesByTopic, getLessonsByTheme } from '../ducks/courseBuilder';
import * as schema from '../ducks/schema';
import { types as forumQuestionsTypes } from '../ducks/forumQuestions';

import * as forumQuestionsSaga from './forumQuestions';

import * as routerSaga from './routes';

// Constants should not be defined here.
const SAVE_COURSE = 'SAVE_COURSE';
const PICK_SOURCE_COURSE = 'PICK_SOURCE_COURSE';
const SEARCH_FOR_MASTER_LESSONS = 'SEARCH_FOR_MASTER_LESSONS';

/**
 * Loads the source course from the back-end.
 *
 * In the course-builder, there is both a `source-course` and a `target-course`.
 *
 * The `target-course` is the course that is being edited (built) and the `source-course` is a
 * course that the teacher can drag-and-drop lessons from onto the `target-course`.
 */
export function* loadSourceCourse(action) {
  try {
    const { courseId } = action;
    yield put({ type: 'START_LOADING_COURSE', courseId });
    const response = yield call(axios.get, `/courses/${courseId}.json`);
    yield put({ type: 'LOAD_COURSE_FROM_DATA', ...normalize(camelcaseKeysDeep(response.data), schema.course) });
    yield all([
      put({ type: 'SET_SOURCE_COURSE', courseId }),
      put({ type: 'FINISH_LOADING_COURSE', courseId }),
    ]);
  } catch (e) {
    console.log('Loading of source course failed:', e.message);
  }
}

/**
 * Searches for a master_lesson by title through the back-end server. The server then responds with
 * a list of master lessons matching the search query.
 */
export function* performMasterLessonSearch(action) {
  try {
    yield put({ type: 'START_SEARCH_FOR_MASTER_LESSONS', title: action.title });
    const response = yield call(axios.get, `/master_lessons/search.json?title=${action.title}`);
    const rawData = camelcaseKeysDeep(response.data);
    const normalizedData = {};
    const matchingMasterLessonIds = rawData.map((masterLesson) => {
      const { id } = masterLesson;
      normalizedData[id] = masterLesson;
      return id;
    });
    yield all([
      put({ type: 'LOAD_MASTER_LESSONS_FROM_DATA', entities: { masterLessons: normalizedData } }),
      put({ type: 'FINISH_SEARCH_FOR_MASTER_LESSONS', matchingMasterLessonIds }),
    ]);
  } catch (e) {
    console.log('Unable to search for master lessons:', e.message);
  }
}

/**
 * Saves a course to the database.
 *
 * Loops through the lessons to create an object that we can send to the Rails back-end.
 *
 * We need to do this looping to correctly mark objects to be deleted in Rails
 * (by adding `_destroy: 1`).
 *
 * We also want to remove the id of the objects that are not yet saved, since otherwise Rails
 * will think we are trying to edit an existing object, rather than creating a new one.
 *
 * We also need to update the `title` and `position` (sorting), which could have been changed
 * as well.
 */
export function* saveCourse(action) {
  yield put({ type: 'SAVE_COURSE_PROCESSING', courseId: action.courseId });
  try {
    const state = yield select();
    const topics = getTopicsByCourse(state, action.courseId, true, true);
    const filteredTopics = [];
    Object.keys(topics).map((topicKey) => {
      const topicId = topics[topicKey].id;
      const {
        title, position, archived, freeAccess,
      } = topics[topicKey];
      const destroyTopic = topics[topicKey].destroy ? { _destroy: 1 } : {};
      const themes = getThemesByTopic(state, topicId, true, true);
      const filteredThemes = [];
      Object.keys(themes).map((themeKey) => {
        const themeId = themes[themeKey].id;
        const destroyTheme = themes[themeKey].destroy ? { _destroy: 1 } : {};
        const lessons = getLessonsByTheme(state, themeId, true, true);
        const filteredLessons = [];
        Object.keys(lessons).map((lessonKey) => {
          const lessonId = lessons[lessonKey].id;
          const { masterLesson } = lessons[lessonKey];
          const destroyLesson = lessons[lessonKey].destroy ? { _destroy: 1 } : {};
          filteredLessons.push({
            id: lessonId > 0 ? lessonId : null,
            position: lessons[lessonKey].position,
            archived: lessons[lessonKey].archived,
            masterLessonId: lessons[lessonKey].masterLessonId,
            masterLessonAttributes: { id: masterLesson.id, title: masterLesson.title },
            freeAccess: lessons[lessonKey].freeAccess,
            ...destroyLesson,
          });
          return filteredLessons;
        });
        filteredThemes.push({
          id: themeId > 0 ? themeId : null,
          title: themes[themeKey].title,
          position: themes[themeKey].position,
          archived: themes[themeKey].archived,
          lessons_attributes: [...filteredLessons],
          freeAccess: themes[themeKey].freeAccess,
          ...destroyTheme,
        });
        return filteredThemes;
      });

      filteredTopics.push({
        id: topicId > 0 ? topicId : null,
        title,
        position,
        archived,
        freeAccess,
        themes_attributes: [...filteredThemes],
        ...destroyTopic,
      });
      return filteredTopics;
    });

    const courseData = keysToSnakeCase({
      authenticityToken: action.authenticityToken,
      course: {
        topicsAttributes: [
          ...filteredTopics,
        ],
      },
      format: 'json',
    });

    const response = yield call(axios.patch, `/courses/${action.courseId}?format=json`, courseData);

    yield all([
      put({ type: 'SAVE_COURSE_SUCCEEDED', courseId: action.courseId }),
      put({ type: 'LOAD_COURSE_FROM_DATA', ...normalize(camelcaseKeysDeep(response.data), schema.course) }),
    ]);
  } catch (e) {
    yield put({ type: 'SAVE_COURSE_FAILED', courseId: action.courseId, error: e.response.data.error || e.message });
  }
}

// This should be part of the rootSaga
export function* watchSaveCourse() {
  yield all([
    takeEvery(PICK_SOURCE_COURSE, loadSourceCourse),
    takeEvery(SAVE_COURSE, saveCourse),
    takeEvery(SEARCH_FOR_MASTER_LESSONS, performMasterLessonSearch),
  ]);
}

/**
 * The rootSaga is the main listener function in `redux-saga`. It will listen for `redux`-events
 * and call the different sagas that match.
 */
export default function* rootSaga() {
  // initiate async parallel control flow with array of listener functions
  yield all([
    watchSaveCourse(),

    takeEvery(forumQuestionsTypes.GET_FORUM_QUESTIONS, forumQuestionsSaga.getForumQuestions),
    takeEvery(forumQuestionsTypes.GET_FORUM_QUESTIONS_FOR_CONVERSATIONS, forumQuestionsSaga.getForumQuestionsForConversations),
    takeEvery(forumQuestionsTypes.GET_FORUM_QUESTIONS_BY_MASTER_LESSON, forumQuestionsSaga.getForumQuestionsByLesson),
    takeEvery(forumQuestionsTypes.GET_FORUM_QUESTIONS_BY_TEACHER, forumQuestionsSaga.getForumQuestionsByTheacher),
    takeEvery(forumQuestionsTypes.GET_FORUM_QUESTIONS_BY_COURSE, forumQuestionsSaga.getForumQuestionsByCourse),
    takeEvery(forumQuestionsTypes.MARK_FORUM_QUESTION_AS_SOLVED, forumQuestionsSaga.markForumQuestionAsSolved),

    takeEvery(LOCATION_CHANGE, routerSaga.changeLocation),
  ]);
}
