// 
// @flow
import React from 'react';
import { chunk } from 'lodash';
import FontAwesome from 'react-fontawesome';
import CourseTablePagination from './CourseTablePagination';
import { timeFromNow } from '../../utilities/date';



class CourseDiscussionsTable extends React.Component {
  state = {
    currentPage: 1,
    perPage: 10,
  };

  paginate = (collection, perPage) => chunk(collection, perPage);

  getPage = (collection, page) => collection[--page] || [];

  lastAnswerIsNotFromSuperAccessStudent = (discussion) => (
    discussion.lastAnswerInComment
    && discussion.lastAnswerInComment.answererType === 'Student'
    && !discussion.lastAnswerInComment.postedBySuperaccessStudent
  );

  discussionDoesNotHaveAnswers = (discussion) => discussion.totalNumberOfComments === 0;

  render() {
    const { onSolveThreadClick } = this.props;
    const { perPage, currentPage } = this.state;

    const discussions = this.paginate(this.props.discussions, perPage);

    return (
      <>
        <div className="discussions-table">
          <table>
            <thead>
              <tr>
                <th className="course-title">Forumpost</th>
                <th>Kommentarer</th>
                <th>Ubesvart post?</th>
                <th>Ubesvart kommentar?</th>
                <th>Siste aktivitet</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.getPage(discussions, currentPage).map(discussion => (
                <tr key={discussion.id}>
                  <td className="course-title">
                    {!discussion.markedAsSolved && (this.discussionDoesNotHaveAnswers(discussion) || this.lastAnswerIsNotFromSuperAccessStudent(discussion))
                      ? <b>{discussion.title}</b>
                      : discussion.title
                    }
                  </td>
                  <td>{discussion.totalNumberOfComments}</td>
                  <td>{!discussion.markedAsSolved && this.discussionDoesNotHaveAnswers(discussion) ? <b>Ja</b> : 'Nei'}</td>
                  <td>{!discussion.markedAsSolved && this.lastAnswerIsNotFromSuperAccessStudent(discussion) ? <b>Ja</b> : 'Nei'}</td>
                  <td>{timeFromNow(discussion.lastCommentedAt)}</td>
                  <td>
                    {!discussion.markedAsSolved && (this.discussionDoesNotHaveAnswers(discussion) || this.lastAnswerIsNotFromSuperAccessStudent(discussion)) && (
                      <button className="solve-thread-button" onClick={() => onSolveThreadClick(discussion.id)}>Marker som løst <FontAwesome name="check" className="pl-2" /></button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <CourseTablePagination
          totalItems={this.props.discussions.length}
          pageNumber={discussions.length}
          onPageChange={(page) => this.setState({ currentPage: page })}
          onPerPageChange={(perPageCount) => this.setState({ currentPage: 1, perPage: perPageCount })}
          perPage={perPage}
        />
      </>
    );
  }
}

export default (CourseDiscussionsTable);
