// 
// @flow
import {
  first,
} from 'lodash';

import { types as coursesTypes } from '../courses';
import { types as pagesTypes } from '../pages';

export const types = {
  COMPLETE_QUIZ: 'SCENES/LESSON/COMPLETE_QUIZ',
  COMPLETE_QUIZ_FAILURE: 'SCENES/LESSON/COMPLETE_QUIZ_FAILURE',
  COMPLETE_QUIZ_SUCCESS: 'SCENES/LESSON/COMPLETE_QUIZ_SUCCESS',
  GO_TO_NEXT_LESSON: 'SCENES/LESSON/GO_TO_NEXT_LESSON',
  GO_TO_QUESTION: 'SCENES/LESSON/GO_TO_QUESTION',
  RESET_QUIZ: 'SCENES/LESSON/RESET_QUIZ',
  SELECT: 'SCENES/LESSON/SELECT',
  SELECT_LESSON_ITEM: 'SCENES/LESSON/SELECT_LESSON_ITEM',
  SELECT_LESSON_IN_EXPLORE: 'SCENES/LESSON/SELECT_LESSON_IN_EXPLORE',
  SELECT_ANSWER: 'SCENES/LESSON/SELECT_ANSWER',
  SET_PROGRESS: 'SCENES/LESSON/SET_PROGRESS',
  TOGGLE_SEARCH_FIELD: 'SCENES/LESSON/TOGGLE_SEARCH_FIELD',
  UPDATE_SEARCH_TEXT: 'SCENES/LESSON/UPDATE_SEARCH_TEXT',
  RESET_SEARCH_FILTER: 'SCENES/LESSON/RESET_SEARCH_FILTER',
  FREE_ACCESS_TOPIC_SELECTED: 'SCENES/LESSON/FREE_ACCESS_TOPIC_SELECTED',
  ANSWER_ENTIRE_QUIZ_AGAIN: 'SCENES/LESSON/ANSWER_ENTIRE_QUIZ_AGAIN',
  ANSWER_WRONGLY_ANSWERED_AGAIN: 'SCENES/LESSON/ANSWER_WRONGLY_ANSWERED_AGAIN',
  MARK_ANSWER_AS_CORRECT: 'SCENES/LESSON/MARK_ANSWER_AS_CORRECT',
  SET_QUIZ_IN_SCENES: 'SCENES/LESSON/SET_QUIZ_IN_SCENES',
};


export const initialState = {
  expandedLessonContentMenu: null,
  freeAccessTopicSelected: false,
  freeVersionLesson: false,
  isLessonItemSelected: false,
  quiz: {
    quizId: null,
    answers: {},
    correctSoFar: 0,
    completed: false,
    currentId: null,
    quizStartedAgainPartially: false,
    quizStartedAfterTotalReset: false,
  },
  search: { isSearching: false, searchText: '' },
  selected: {
    model: 'themes', id: null, parentId: null, timestamp: null,
  },
  selectedLessonInExplore: null,
};

const getFirstFromObject = (obj) => first(Object.values(obj));

const lesson = (state = initialState, action) => {
  switch (action.type) {
    case coursesTypes.CONTINUE_COURSE_SUCCESS:
      return {
        ...state,
        freeAccessTopicSelected: action.freeVersionLesson,
        freeVersionLesson: action.freeVersionLesson,
        selected: {
          model: 'themes',
          id: getFirstFromObject(action.entities.lessons).themeId,
          parentId: action.parentId,
          timestamp: null,
        },
      };
      return {
        ...state,
        selected: {
          model: 'themes', id: action.data.theme_id, parentId: action.parentId, timestamp: null,
        },
      };
    case pagesTypes.CHANGE_PAGE:
      return {
        ...state,
        quiz: {
          quizId: null,
          answers: {},
          correctSoFar: 0,
          completed: false,
          currentId: null,
          quizStartedAgainPartially: false,
          quizStartedAfterTotalReset: false,
        },
      };

    case types.SET_QUIZ_IN_SCENES:
      return { ...state, quiz: { ...state.quiz, quizId: action.quizId } };

    case types.COMPLETE_QUIZ:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          completed: true,
          quizStartedAgainPartially: false,
          quizStartedAfterTotalReset: false,
        },
      };

    case types.GO_TO_QUESTION:
      return { ...state, quiz: { ...state.quiz, currentId: action.questionId } };

    case types.RESET_QUIZ:
      return {
        ...state,
        quiz: {
          quizId: null,
          answers: {},
          correctSoFar: 0,
          completed: false,
          currentId: null,
          quizStartedAgainPartially: false,
          quizStartedAfterTotalReset: false,
        },
      };

    case types.ANSWER_ENTIRE_QUIZ_AGAIN:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answers: {},
          correctSoFar: 0,
          completed: false,
          currentId: null,
          quizStartedAgainPartially: false,
          quizStartedAfterTotalReset: true,
        },
      };

    case types.ANSWER_WRONGLY_ANSWERED_AGAIN:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answers: {},
          correctSoFar: action.correctlyAnsweredQuestionsNumber,
          completed: false,
          currentId: null,
          quizStartedAgainPartially: true,
          quizStartedAfterTotalReset: false,
        },
      };

    case types.SELECT:
      const nextState = {
        ...state,
        selected: {
          model: action.model, id: action.id, parentId: action.parentId, timestamp: action.timestamp,
        },
      };
      if ((action.parentId && action.parentId === -1) || (!action.parentId && action.id === -1)) {
        nextState.freeAccessTopicSelected = true;
      }
      if (action.parentId && action.parentId !== -1) {
        nextState.freeAccessTopicSelected = false;
      }
      return nextState;

    case types.SELECT_ANSWER:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          answers: { ...state.quiz.answers, [action.questionId]: action.answerId },
          correctSoFar: action.isCorrect ? state.quiz.correctSoFar + 1 : state.quiz.correctSoFar,
        },
      };

    case types.SELECT_LESSON_ITEM:
      return { ...state, isLessonItemSelected: action.selected };

    case types.SELECT_LESSON_IN_EXPLORE:
      return { ...state, selectedLessonInExplore: { lesson: action.lessonItem, courseId: action.courseId } };

    case types.MARK_ANSWER_AS_CORRECT:
      return { ...state, quiz: { ...state.quiz, correctSoFar: state.quiz.correctSoFar + 1 } };

    case types.TOGGLE_SEARCH_FIELD:
      return { ...state, search: { ...state.search, isSearching: !state.search.isSearching } };

    case types.UPDATE_SEARCH_TEXT:
      return { ...state, search: { ...state.search, searchText: action.searchText } };

    case types.RESET_SEARCH_FILTER:
      return {
        ...state,
        search: initialState.search,
      };
    default:
      return state;
  }
};

export default lesson;

export const actions = {
  completeQuiz: (lessonId, completedAfterPartialTry = false) => ({ type: types.COMPLETE_QUIZ, lessonId, completedAfterPartialTry }),
  goToNextLesson: (currentLessonId, progress = undefined) => (
    { type: types.GO_TO_NEXT_LESSON, currentLessonId, progress }
  ),
  goToQuestion: (questionId) => ({ type: types.GO_TO_QUESTION, questionId }),
  resetQuiz: () => ({ type: types.RESET_QUIZ }),
  select: (model, id, parentId, timestamp) => ({
    type: types.SELECT, model, id, parentId, timestamp,
  }),
  selectAnswer: (questionId, answerId, isCorrect) => ({
    type: types.SELECT_ANSWER, questionId, answerId, isCorrect,
  }),
  setProgress: (id, progress) => ({ type: types.SET_PROGRESS, id, progress }),
  toggleSearchField: () => ({ type: types.TOGGLE_SEARCH_FIELD }),
  updateSearchLessonText: (searchText) => ({ type: types.UPDATE_SEARCH_TEXT, searchText }),
  resetSearchFilter: () => ({ type: types.RESET_SEARCH_FILTER }),
  setFreeAccessTopic: () => ({ type: types.FREE_ACCESS_TOPIC_SELECTED }),
  answerEntireQuizAgain: (lessonId) => ({ type: types.ANSWER_ENTIRE_QUIZ_AGAIN, lessonId }),
  answerWronglyAnsweredAgain: (correctlyAnsweredQuestionsNumber) => ({ type: types.ANSWER_WRONGLY_ANSWERED_AGAIN, correctlyAnsweredQuestionsNumber }),
  markAnswerAsCorrect: () => ({ type: types.MARK_ANSWER_AS_CORRECT }),
  setQuizInScenes: (quizId) => ({ type: types.SET_QUIZ_IN_SCENES, quizId }),
  selectLessonItem: (selected) => ({ type: types.SELECT_LESSON_ITEM, selected }),
  selectLessonInExplore: (lessonItem, courseId) => ({ type: types.SELECT_LESSON_IN_EXPLORE, lessonItem, courseId }),
};

// Simple selectors
export const selectors = {
  getSearch: (state) => state.scenes.lesson.search,
  getSearchText: (state) => state.scenes.lesson.search.searchText,
  getSelected: (state) => state.scenes.lesson.selected,
  getExpandedTopics: (state, courseId) => {
    const { expandedLessonContentMenu } = state.scenes.lesson;
    if (expandedLessonContentMenu) {
      const course = expandedLessonContentMenu[courseId];
      if (course) {
        return course.topics;
      }
      return null;
    }
  },
  getSelectedAnswerIdForQuizQuestion: (state, questionId) => state.scenes.lesson.quiz.answers[questionId],
  getSelectedAnswerIds: (state) => state.scenes.lesson.quiz.answers,
  getCurrentQuizQuestionId: (state) => state.scenes.lesson.quiz.currentId,
  isQuizCompleted: (state) => state.scenes.lesson.quiz.completed,
  wasQuizCompletedEverBefore: (state, id) => !!state.entities.lessonProgresses[id],
};
