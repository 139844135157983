// 
// @flow
import { schema } from 'normalizr';

export const quizAnswer = new schema.Entity('quizAnswers');
export const quizQuestion = new schema.Entity('quizQuestions', {
  quizAnswers: [quizAnswer],
});
export const quizQuestions = [quizQuestion];
export const quiz = new schema.Entity('quizzes', {
  quizQuestions: [quizQuestion],
});
export const masterLesson = new schema.Entity('masterLessons', {
  quiz,
});
export const masterLessonType = new schema.Entity('masterLessons');
export const masterLessons = [masterLessonType];
export const lesson = new schema.Entity('lessons', {
  masterLesson,
});
export const theme = new schema.Entity('themes', {
  lessons: [lesson],
});
export const topic = new schema.Entity('topics', {
  themes: [theme],
});
export const course = new schema.Entity('courses', {
  topics: [topic],
});
export const courseList = [course];

export const payment = new schema.Entity('payments');
export const payments = { payments: [payment] };

export const subscription = new schema.Entity('subscriptions');
export const subscriptions = { subscriptions: [subscription] };

export const university = new schema.Entity('universities');
export const universityList = [university];

export const courseCategory = new schema.Entity('courseCategories');
export const courseCategoryList = [courseCategory];

export const favouriteCourse = new schema.Entity('favourite_courses');
export const favouriteCoursesList = { favouriteCourses: [favouriteCourse] };

export const specialisation = new schema.Entity('specialisations');
export const specialisationList = [specialisation];

export const lessonNote = new schema.Entity('lessonNotes', {
  lesson,
}, {
  idAttribute: 'lessonId',
});

export const campaign = new schema.Entity('campaigns');
export const campaigns = [campaign];

export const forumQuestion = new schema.Entity('forumQuestions');
export const forumQuestions = [forumQuestion];

export const forumReply = new schema.Entity('forumReplies');
export const forumReplies = [forumReply];

export const courseResource = new schema.Entity('courseResources');
export const courseResourcesList = [courseResource];

export const masterLessonResource = new schema.Entity('masterLessonResources');
export const masterLessonResourcesList = [masterLessonResource];
