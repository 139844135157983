// 
// @flow


export const types = {
  UPDATE_UNANSWERED_QUESTION: 'DISCUSSIONS/UPDATE_UNANSWERED_QUESTION',
  UPDATE_UNANSWERED_ANSWER: 'DISCUSSIONS/UPDATE_UNANSWERED_ANSWER',
  UPDATE_UNANSWERED_REPLY: 'DISCUSSIONS/UPDATE_UNANSWERED_REPLY',
};

const defaultState = {
  unansweredCounts: {
    unansweredPerTeacher: {},
    unansweredPerCourse: {},
    unansweredPerCourseForum: {},
  },
};

export default (state = defaultState, action) => {
  if (action.type === types.UPDATE_UNANSWERED_QUESTION && action.unanswered) {
    return {
      ...state,
      unansweredCounts: {
        ...action.unanswered,
      },
    };
  }

  return state;
};
