// 
// @flow
import React, { useState } from 'react';


const CheckboxList = ({ items, inputVariableName }) => {
  const [checked, setChecked] = useState([]);
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  return (
    <div className="modal-scrollable-list checklist mt-4">
      <div className="title">Select resources</div>
      {items.map((item) => (
        <div className='item-wrapper' key={item.id}>
          <input value={item.id} type="checkbox" onChange={handleCheck} className='checkbox' name={inputVariableName} />
          <span>{item.title}&nbsp;</span> - <span>&nbsp;{item.id}</span>
        </div>
      ))}
    </div>
  );
};

export default (CheckboxList);
