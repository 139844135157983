// 
// @flow

const _ = require('lodash');

/**
 * Converts all the keys in an object from camelCase to snake_case
 *
 * @example
 *   import keysToSnakeCase from './snakeToCamelCase';
 *   keysToSnakeCase({badKey: 1});   => {bad_Key: 1}
 *   keysToSnakeCase([{badKey: 1}]); => [{bad_Key: 1}]
 */
function keysToSnakeCase(object) {
  let snakeCaseObject = _.cloneDeep(object);

  if (_.isArray(snakeCaseObject)) {
    return _.map(snakeCaseObject, keysToSnakeCase);
  }
  snakeCaseObject = _.mapKeys(snakeCaseObject, (value, key) => {
    if (key.charAt(0) === '_') {
      return `_${_.snakeCase(key.substring(1))}`;
    }
    return _.snakeCase(key);
  });

  // Recursively apply throughout object
  return _.mapValues(snakeCaseObject, (value) => {
    if (_.isPlainObject(value)) {
      return keysToSnakeCase(value);
    } else if (_.isArray(value)) {
      return _.map(value, keysToSnakeCase);
    }
    return value;
  });
}

export default keysToSnakeCase;
