// 
// @flow
import {
  filter, isEmpty, merge, sortBy, uniqBy,
  map,
} from 'lodash';

import { selectors as topicsSelectors } from './topics';
import { selectors as coursesSelectors } from './courses';

export const types = {
  EXPAND_THEME: 'THEMES/EXPAND_THEME',
};

export default (state = {}, action) => {
  if (action.entities && action.entities.themes) {
    return merge({}, state, action.entities.themes);
  }

  return state;
};

export const actions = {
  expandTheme: (id) => ({ type: types.EXPAND_THEME, id }),
};

// Simple selectors
export const selectors = {
  getTheme: (state, id) => state.entities.themes[id],
  getThemes: (state, id) => (
    sortBy(filter(state.entities.themes, { topicId: id }), theme => theme.position)
  ),
  getNextTheme: (state, currentThemeId) => {
    const currentTheme = selectors.getTheme(state, currentThemeId);
    if (currentTheme === undefined) return undefined;
    const themes = selectors.getThemes(state, currentTheme.topicId);
    if (isEmpty(themes)) return undefined;
    const currentThemeIndex = themes.findIndex(theme => theme.id === currentThemeId);
    if (currentThemeIndex < 0) return undefined;
    const nextTheme = themes[currentThemeIndex + 1];
    if (nextTheme === undefined) {
      const nextTopic = topicsSelectors.getNextTopic(state, currentTheme.topicId);
      if (nextTopic === undefined) return undefined;
      const nextTopicThemes = selectors.getThemes(state, nextTopic.id);
      return nextTopicThemes[0];
    }
    return nextTheme;
  },
  getThemesByLesson: (state, lessonId) => {
    const topic = topicsSelectors.getTopicByLesson(state, lessonId);
    if (topic === undefined) return undefined;
    return selectors.getThemes(state, topic.id);
  },
  getThemesWithSameTopic: (state, themeId) => {
    const theme = selectors.getTheme(state, themeId);
    if (theme === undefined) return undefined;
    const { topicId } = theme;
    return selectors.getThemes(state, topicId);
  },
  getThemesWithFreeAccess: (state, courseId) => {
    const lessons = coursesSelectors.getLessonsWithFreeAccess(state, courseId);
    let themes = map(lessons, l => selectors.getThemeByLesson(state, l.id));
    themes = uniqBy(themes, 'id');
    const themesWithTopicPosition = map(themes, (theme) => {
      const topicPosition = topicsSelectors.getTopic(state, theme.topicId);
      theme.topicPosition = topicPosition.position;
      return theme;
    });

    return sortBy(themesWithTopicPosition, 'topicPosition', 'position');
  },
  getThemeProgressByFreeLessons: (state, themeId) => selectors.getThemeProgress(state, themeId, true),
};
