// 
// @flow
import flow from 'lodash/flow';
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { getMatchingMasterLessons } from '../../ducks/courseBuilder';
import MasterLesson from './MasterLesson';


class LessonsList extends Component {
  render() {
    const { isSearchingForMasterLessons, masterLessons } = this.props;

    return (
      <div className="sortable-lessons-list">
        <div className="row sortable-lessons-list__header">
          <div className="col-7">
            <h4>Matching Master Lessons</h4>
          </div>
        </div>
        <div className="sortable-lessons-list__lessons">
          {
            isSearchingForMasterLessons && (
              <FontAwesome
                name="circle-o-notch"
                size="4x"
                spin
              />
            )
          }
          <div className="dd">
            <ol className="dd-list">
              {
                masterLessons && Object.keys(masterLessons).length === 0 && !isSearchingForMasterLessons ? (
                  <p>No matching master lessons found!</p>
                ) : (
                  masterLessons && Object.keys(masterLessons).map((key, i) => {
                    const masterLesson = masterLessons[key];
                    return (
                      <MasterLesson
                        key={masterLesson.id}
                        id={masterLesson.id}
                        index={i}
                        category={masterLesson.category}
                        difficulty={masterLesson.difficulty}
                        title={masterLesson.title}
                        locked
                      />
                    );
                  })
                )
              }
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterLessons: getMatchingMasterLessons(state),
  isSearchingForMasterLessons: state.isSearchingForMasterLessons,
});

export default (flow(
  (connect(mapStateToProps)),
)(LessonsList));
