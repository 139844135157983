// 
// @flow
import React from 'react';
import Select from 'react-select';


class SingleSearchableSelectorContainer extends React.Component {
  render() {
    const { collection, previouslySelected, name } = this.props;
    const options = collection.map(item => ({ value: item.id, label: item.title }));
    const selected = previouslySelected ? options.find((option) => option.value === previouslySelected) : null;
    return (
      <Select
        isClearable
        isSearchable
        options={options}
        defaultValue={selected}
        name={name}
        onChange={(value) => this.props.onHandleChange && this.props.onHandleChange(value)}
      />
    );
  }
}

export default (SingleSearchableSelectorContainer);
