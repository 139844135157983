// 
// @flow

/**
 * @return random integer.
 *
 * By default it will return a an integer between -99999 and -10000.
 */
export const getRandomInt = (min = -99999, max = -10000) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt)) + minInt;
};
