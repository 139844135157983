// 
// @flow
import React from 'react';
import DefaultSpinner from '../../core/Spinners/DefaultSpinner';



class MasterLessonConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  render() {
    const {
      onConfirmationModalClose, editMasterUrl, lessonId,
    } = this.props;
    const { clicked } = this.state;

    return (
      <div className="modal-dialog" id="confirmation-modal-box" style={{ minWidth: '600px !important' }}>
        <div className="modal-content" style={{ width: '100%' }}>
          <div className={`edit-master-lesson-modal-wrapper ${clicked ? 'is-processing' : ''}`}>
            <div className="modal-header">
              <h3 className="modal-title">
                Are you sure you want to do this?
              </h3>
              <div className="close" onClick={onConfirmationModalClose}>
                <span>&times;</span>
              </div>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className="col-xl-12">
                  <p>Are you sure you want to edit for this course only?</p>
                  <p>Choosing this option duplicates the master lesson and the new master lesson will no longer be shared with the other courses.</p>
                  <p>This action is irreversible.</p>
                </div>
                <div className="col-xl-12 d-flex justify-content-around">
                  <div
                    onClick={onConfirmationModalClose}
                    className="btn btn-xs btn-success"
                  >
                    No, cancel and go back
                  </div>
                  <a
                    href={`${editMasterUrl}&clone=true&lesson_id=${lessonId}`}
                    className="btn clone btn-xs btn-secondary"
                    onClick={this.handleClick}
                  >
                    {clicked ? (
                      <div className="inline-wrapper">
                        <DefaultSpinner /> Processing...
                      </div>
                    ) : (
                      <div className="inline-wrapper">
                        Yes, edit for this course only
                      </div>
                    )}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleClick = () => {
    this.setState({ clicked: true });
  };
}

export default (MasterLessonConfirmationModal);
