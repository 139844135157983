// 
/* eslint-disable react/no-array-index-key */
// @flow
import React from 'react';
import { uniqueId } from 'lodash';


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    let pages = [];

    if (totalPages <= 3) {
      // If total pages are 3 or less, show all pages
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else if (currentPage <= 2) {
      pages = [1, 2, 3, '...', totalPages];
    } else if (currentPage >= totalPages - 1) {
      pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
    } else {
      pages = [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
      ];
    }

    return pages.map((page) => (page === '...' ? (
      <li key={uniqueId()} className="page-item disabled">
        <span className="page-link">{page}</span>
      </li>
    ) : (
      <li
        key={uniqueId()}
        className={`page-item ${currentPage === page ? 'active' : ''}`}
      >
        <button className="page-link" onClick={() => onPageChange(page)}>
          {page}
        </button>
      </li>
    )),
    );
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={handlePrevious}>
            Previous
          </button>
        </li>
        {renderPageNumbers()}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={handleNext}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
