// 
// @flow
import { isEmpty, merge, filter } from 'lodash';

import { selectors as lessonsSelectors } from './lessons';
import { selectors as coursesSelectors } from './courses';

export const types = {
  GET_MASTER_LESSON: 'MASTER_LESSON/GET_MASTER_LESSON',
  GET_MASTER_LESSON_SUCESSS: 'MASTER_LESSON/GET_MASTER_LESSON_SUCESSS',
  GET_MASTER_LESSON_FAILURE: 'MASTER_LESSON/GET_MASTER_LESSON_FAILURE',

  CLONE_MASTER_LESSON: 'MASTER_LESSONS/CLONE_MASTER_LESSON',
  CLONE_MASTER_LESSON_SUCCESS: 'MASTER_LESSONS/CLONE_MASTER_LESSON_SUCCESS',
  CLONE_MASTER_LESSON_FAILURE: 'MASTER_LESSONS/CLONE_MASTER_LESSON_FAILURE',
};

export default (state = {}, action) => {
  if (action.entities && action.entities.masterLessons) {
    return merge({}, state, action.entities.masterLessons);
  }

  return state;
};

export const actions = {
  getMasterLesson: (id) => ({ type: types.GET_MASTER_LESSON, id }),
  cloneMasterLesson: (id, lessonId) => ({ type: types.CLONE_MASTER_LESSON, id, lessonId }),
};

const getMasterLesson = (state, id) => state.entities.masterLessons[id];
const getMasterLessonByLesson = (state, lessonId) => state.entities.masterLessons[state.entities.lessons[lessonId].masterLessonId];

// Simple selectors
export const selectors = {
  getMasterLesson,
  getMasterLessonByLesson,
  getMasterLessonsByTheme: (state, themeId) => {
    const lessons = lessonsSelectors.getLessons(state, themeId);
    if (isEmpty(lessons)) return undefined;
    const masterLessons = [];
    Object.keys(lessons).forEach((key, index) => { masterLessons[index] = selectors.getMasterLessonByLesson(state, lessons[key].id); });
    return masterLessons;
  },
  getMasterLessonsByTopic: (state, topicId) => {
    const lessons = lessonsSelectors.getLessonsByTopic(state, topicId);
    if (isEmpty(lessons)) return undefined;
    const masterLessons = [];
    Object.keys(lessons).forEach((key, index) => { masterLessons[index] = selectors.getMasterLessonByLesson(state, lessons[key].id); });
    return masterLessons;
  },
  getMasterLessonFromLessonId: (state, lessonId) => {
    const lesson = lessonsSelectors.getLesson(state, lessonId);
    if (lesson === undefined) return undefined;
    return state.entities.masterLessons[lesson.masterLesson];
  },
  getMasterLessonsByCourseWhereLessonsAreFree: (state, courseId) => {
    const lessons = coursesSelectors.getLessonsWithFreeAccess(state, courseId);
    const lessonIds = lessons.map(l => l.id);
    const masterLessons = lessonIds.map((id) => selectors.getMasterLessonByLesson(state, id));

    return masterLessons;
  },
  getMasterLessonByThemeWhereLessonsAreFree: (state, themeId) => {
    const lessons = filter(lessonsSelectors.getLessonsByTheme(state, themeId), 'freeAccess');
    const lessonIds = lessons.map(l => l.id);
    const masterLessons = lessonIds.map((id) => selectors.getMasterLessonByLesson(state, id));

    return masterLessons;
  },
};
