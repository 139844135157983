// 
// @flow
import FontAwesome from 'react-fontawesome';
import {
  Button, Input, InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import React from 'react';


class InputWithButton extends React.Component {
  render() {
    const {
      name, onChange, onClick,
      disabled, value,
    } = this.props;
    return (
      <InputGroup>
        <Input
          className="react-datepicker-ignore-onclickoutside"
          name={name}
          onClick={onClick}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <InputGroupAddon addonType="append">
          <Button
            className="react-datepicker-ignore-onclickoutside"
            disabled={disabled}
            onClick={onClick}
          >
            <FontAwesome name='calendar' />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default (InputWithButton);
