// 
// @flow
/* eslint react/no-unused-prop-types: 0 */
import React, { useState, useRef } from 'react';
import flow from 'lodash/flow';
import { merge } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import * as util from '../../utilities';
import { MASTER_LESSON, LESSON } from '../constants';
import { actions } from '../../ducks/courseBuilder';
import { actions as masterLessonsActions } from '../../ducks/masterLessons';
import EditMasterLessonModal from './EditMasterLessonModal';
import MasterLessonConfirmationModal from './MasterLessonConfirmationModal';
import ArchiveConfirmationModal from './ArchiveConfirmationModal';
import ToggleAccessButton from './ToggleAccessButton';



const Lesson = (props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  const {
    category, dirty, fake, locked, masterLessonId,
    routes, themeId, title, associatedCourses, archived,
    freeAccess, toggleLessonAccess, id, courseId, index,
  } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: LESSON,
      drag() {
        return {
          index: props.index,
          themeId: props.themeId,
          type: LESSON,
          id: props.id,
          courseId: props.courseId,
        };
      },
      item: {
        id, courseId, themeId, index, type: LESSON,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [index],
  );

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [LESSON, MASTER_LESSON],
      drop(item) {
        if (index === item.index) return;
        if (item.type === MASTER_LESSON) {
          const newId = util.getRandomInt();
          props.addLessonFromMasterLesson(newId, index, props.themeId, props.topicId, props.courseId, item.id, item.masterLesson);
        } else if (item.courseId === props.courseId) {
          // id, index, themeId, prevThemeId, courseId
          props.moveLesson(item.id, index, props.themeId, item.themeId, props.courseId);
        } else {
          const newId = util.getRandomInt();
          props.copyLesson(item.id, newId, index, props.themeId, props.topicId, props.courseId);
        }

        return {
          type: item.type,
          themeId: item.themeId,
        };
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [index],
  );

  const isTarget = canDrop && isOver;
  const opacity = isDragging ? 0.2 : 1;
  const underline = isTarget ? { borderBottom: '40px solid #5CB85C' } : {};

  const ref = useRef(null); // Initialize the reference

  let lessonIcon;
  switch (category) {
    case 'quiz':
      lessonIcon = <FontAwesome name="question-circle" />;
      break;
    case 'article':
      lessonIcon = <FontAwesome name="paperclip" />;
      break;
    default:
      lessonIcon = <FontAwesome name="television" />;
  }

  const handleArchive = () => {
    props.archiveLesson(id, courseId);
  };

  const handleUnarchive = () => {
    props.unarchiveLesson(id, courseId, props.topicId, themeId);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleConfirmationModalOpen = () => {
    handleEditModalClose();
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleArchiveModalOpen = () => {
    setIsArchiveModalOpen(true);
  };

  const handleArchiveModalClose = () => {
    setIsArchiveModalOpen(false);
  };

  drag(drop(ref));

  if (fake) {
    const listStyle = { minHeight: '3px' };
    const handleStyle = { padding: 0, border: 'none', borderBottom: 'none' };

    // eslint-disable-next-line function-call-argument-newline
    return (
      <li ref={ref} className="dd-item" style={{ ...listStyle, opacity }}>
        <div className="dd-handle" style={{ ...handleStyle, ...underline }} />
      </li>
    );
  }

  // eslint-disable-next-line function-call-argument-newline
  return (
    <li ref={ref} className="dd-item" style={{ opacity }}>
      <div className={'dd-handle' + (archived ? ' archived' : '')} style={{ ...underline }}>
        { !locked && (
          <span className="pull-right">
            <Button
              color="secondary"
              size="xxs"
              disabled={dirty}
              onClick={handleEditModalOpen}
            >
              Edit Master
            </Button>
            {' '}
            { archived && <Button color="danger" size="xxs" onClick={handleUnarchive}>Unarchive</Button> }
            { !archived && <Button color="danger" size="xxs" onClick={handleArchiveModalOpen}>Archive</Button> }
            <ToggleAccessButton freeAccess={freeAccess} onToggle={() => toggleLessonAccess(id, courseId, !freeAccess)} />
          </span>
        )
          }
        <span className="left-spacer">
          { lessonIcon }{' '}{ title }{' '}
        </span>
        { isEditModalOpen && (
        <div className="modal-wrapper">
          <EditMasterLessonModal
            associatedCourses={associatedCourses}
            editMasterUrl={`${routes.rootUrl}master_lessons/${masterLessonId}/edit?theme_id=${themeId}`}
            onEditModalClose={handleEditModalClose}
            onConfirmationModalOpen={handleConfirmationModalOpen}
            currentCourseId={courseId}
          />
        </div>
        )}
        { isConfirmationModalOpen && (
        <div className="modal-wrapper">
          <MasterLessonConfirmationModal
            editMasterUrl={`${routes.rootUrl}master_lessons/${masterLessonId}/edit?theme_id=${themeId}`}
            onConfirmationModalClose={handleConfirmationModalClose}
            lessonId={props.id}
          />
        </div>
        )}
        { isArchiveModalOpen && (
        <div className="modal-wrapper">
          <ArchiveConfirmationModal
            entityTitle={title}
            entityType="lesson"
            closeModal={handleArchiveModalClose}
            onArchive={handleArchive}
          />
        </div>
        )}
      </div>
    </li>
  );
};

const mapStateToProps = state => ({ routes: state.routes });

export default (flow((connect(mapStateToProps, merge(actions, masterLessonsActions))),
)(Lesson));
