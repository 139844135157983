// 
// @flow
import camelcaseKeysDeep from 'camelcase-keys-deep';
import { normalize } from 'normalizr';
import { call, put } from 'redux-saga/effects';

import { types } from '../ducks/forumQuestions';
import { types as discussionsTypes } from '../ducks/scenes/discussions';
import * as api from '../api/forumQuestionsApi';
import * as schema from '../ducks/schema';

export function* getForumQuestions() {
  try {
    const response = yield call(api.getForumQuestions);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestions);
    yield put({ type: types.GET_FORUM_QUESTIONS_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.GET_FORUM_QUESTIONS_FAILURE, error: e.data.error });
  }
}

export function* getForumQuestionsForConversations() {
  try {
    const response = yield call(api.getForumQuestionsForConversations);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestions);
    yield put({ type: types.GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_FAILURE, error: e.data.error });
  }
}

export function* getForumQuestionsByLesson(action) {
  try {
    const response = yield call(api.getForumQuestionsByLesson, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestions);
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_MASTER_LESSON_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_MASTER_LESSON_FAILURE, error: e.data.error });
  }
}

export function* getForumQuestionsByTheacher(action) {
  try {
    const response = yield call(api.getForumQuestionsByTheacher, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestions);
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_TEACHER_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_TEACHER_FAILURE, error: e.data.error });
  }
}

export function* getForumQuestionsByCourse(action) {
  try {
    const response = yield call(api.getForumQuestionsByCourse, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestions);
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_COURSE_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.GET_FORUM_QUESTIONS_BY_COURSE_FAILURE, error: e.data.error });
  }
}

export function* postToggleFollowForumQuestion(action) {
  try {
    const response = yield call(api.postToggleFollowQuestion, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestion);
    yield put({ type: types.POST_TOGGLE_FOLLOW_QUESTION_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.POST_TOGGLE_FOLLOW_QUESTION_FAILURE, error: e.data.error });
  }
}

export function* postToggleTeacherReview(action) {
  try {
    const response = yield call(api.postToggleTeacherReview, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data.question), schema.forumQuestion);
    yield put({ type: types.POST_TOGGLE_REVIEW_QUESTION_SUCCESS, entities: normalizedData.entities });
    yield put({ type: discussionsTypes.UPDATE_UNANSWERED_QUESTION, unanswered: camelcaseKeysDeep(response.data.unanswered) });
  } catch (e) {
    yield put({ type: types.POST_TOGGLE_REVIEW_QUESTION_FAILURE, error: e.data.error });
  }
}

export function* markForumQuestionAsSolved(action) {
  try {
    const response = yield call(api.markForumQuestionAsSolved, action.id);
    const normalizedData = normalize(camelcaseKeysDeep(response.data), schema.forumQuestion);
    yield put({ type: types.MARK_FORUM_QUESTION_AS_SOLVED_SUCCESS, entities: normalizedData.entities });
  } catch (e) {
    yield put({ type: types.MARK_FORUM_QUESTION_AS_SOLVED_FAILURE, error: e.data.error });
  }
}
