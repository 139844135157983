// 
// @flow
import {
  combineReducers, applyMiddleware, compose,
  createStore,
} from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import forumQuestions from '../../ducks/forumQuestions';
import discussions from '../../ducks/scenes/discussions';
import courses from '../../ducks/courses';
import errors from '../../ducks/errors';
import saga from '../../sagas';

const configureStore = (initialState) => {
  const { routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
    // other options if needed
  });
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [routerMiddleware, sagaMiddleware];

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */

  initialState = {
    entities: {
      ...initialState,
    },
  };

  const store = (createStore(
    combineReducers({
      entities: combineReducers({
        forumQuestions, courses,
      }),
      errors,
      discussions,
      router: routerReducer,
    }),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  ));

  sagaMiddleware.run(saga);
  return store;
};

export default configureStore;
