// 
// @flow
import React, { Component } from 'react';
import {
  Pagination, PaginationItem, PaginationLink, Input,
} from 'reactstrap';
import { range, toInteger } from 'lodash';



class CourseTablePagination extends Component {
  state = {
    currentPage: 1,
  };

  componentDidUpdate(prevProps, prevState) {
    const { onPageChange } = this.props;
    const { currentPage } = this.state;
    if (prevState.currentPage !== currentPage) {
      onPageChange(currentPage);
    }
  }

  handleNextClick = (e) => {
    e.preventDefault();
    const { currentPage } = this.state;
    const { pageNumber } = this.props;
    if (currentPage + 1 <= pageNumber) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handlePrevClick = (e) => {
    e.preventDefault();
    const { currentPage } = this.state;
    if (currentPage - 1 > 0) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleSetPageClick = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerpageChange = (e) => {
    e.preventDefault();
    const { onPerPageChange } = this.props;
    this.setState({ currentPage: 1 });
    onPerPageChange(toInteger(e.target.value));
  };

  getPagesNumeration = () => {
    const { pageNumber } = this.props;
    const { currentPage } = this.state;
    const pages = range(currentPage - 3, currentPage + 2);
    const pagesOver = pages.filter(p => p > pageNumber).length;
    if (pagesOver) {
      return pages.map(p => p - pagesOver);
    }
    const pagesUnder = pages.filter(p => p < 1).length;
    if (pagesUnder) {
      return pages.map(p => p + pagesUnder);
    }

    return pages;
  };

  render() {
    const { pageNumber, perPage, totalItems } = this.props;
    const { currentPage } = this.state;
    const pages = this.getPagesNumeration().filter(p => p > 0 && p <= pageNumber);
    const itemsTo = currentPage * perPage > totalItems ? totalItems : currentPage * perPage;
    const pageInfo = `${(currentPage * perPage) - (perPage - 1)}-${itemsTo} of ${totalItems}`;

    return (
      <div className="course-discussions-pagination">
        <p className="pages-info">{pageInfo}</p>
        <div className="d-flex justify-content-around per-row">
          <p className="per-row-text">Rows per site: </p>
          <div className="selectbox-wrapper">
            <Input type="select" name="select" id="exampleSelect" value={perPage} onChange={this.handlePerpageChange}>
              {range(10, 40, 10).map(n => <option key={n}>{n}</option>)}
            </Input>
          </div>
        </div>
        <Pagination aria-label="Page navigation example">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink previous href="#" onClick={this.handlePrevClick} />
          </PaginationItem>
          {pages.map(n => (
            <PaginationItem key={n} active={n === currentPage}>
              <PaginationLink href="#" onClick={e => { e.preventDefault(); this.handleSetPageClick(n); }}>
                {n}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem disabled={currentPage === pageNumber}>
            <PaginationLink next href="#" onClick={this.handleNextClick} />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

export default (CourseTablePagination);
