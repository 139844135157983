// 
// @flow
/* eslint react/no-unused-prop-types: 0 */
import flow from 'lodash/flow';
import React, { useState, useRef } from 'react';
import { find } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import {
  Button, Form, FormGroup,
  Input,
} from 'reactstrap';

import * as util from '../../utilities';
import { THEME } from '../constants';
import { actions, getLessonsByTheme } from '../../ducks/courseBuilder';
import { selectors as masterLessonSelectors } from '../../ducks/masterLessons';
import Lesson from './Lesson';
import ArchiveConfirmationModal from './ArchiveConfirmationModal';
import ToggleAccessButton from './ToggleAccessButton';



const Theme = (props) => {
  const [isArchiveModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(props.title === undefined);
  const [newTitle, setNewTitle] = useState(props.title || '');
  const [isOpen, setIsOpen] = useState((window.sessionStorage.getItem(`theme_accordion_open_id_${props.id}`)
  && JSON.parse(window.sessionStorage.getItem(`theme_accordion_open_id_${props.id}`)).isOpen)
    ? JSON.parse(window.sessionStorage.getItem(`theme_accordion_open_id_${props.id}`)).isOpen
    : false);

  const ref = useRef(null); // Initialize the reference
  const { freeAccess, toggleThemeAccess } = props;
  const {
    courseId, dirty, fake,
    id, locked, routes,
    title, lessons, masterLessons,
    archived, topicId, index, hideArchived,
  } = props;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: THEME,
      drag() {
        return {
          id,
          index,
          courseId,
          type: THEME,
          themes: Object.keys(lessons).map(key => [key].id),
        };
      },
      item: {
        id, courseId, index, topicId, lessons: Object.keys(lessons).map(key => lessons[key].id), type: THEME,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [index],
  );

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: THEME,
      drop(item) {
        const {
          moveTheme, copyTheme,
        } = props;
        if (index === item.index) return;

        if (item.topicId === topicId) {
          // We are simply sorting the theme within the topic
          const indexChange = index - item.index;
          moveTheme(item.id, indexChange, topicId, item.topicId, courseId);
        } else {
        // We are moving the theme to a new topic
          const newThemeId = util.getRandomInt();
          // COPY THEME NOW ALSO DELETES SOURCE THEME FROM WHICH COPY WAS MADE
          copyTheme(item.id, newThemeId, index, topicId, courseId, item.topicId);
        }

        return {
          type: item.type,
          topicId: item.topicId,
        };
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [index],
  );

  const handleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleClick = () => {
    setIsEditingTitle(true);
  };

  const handleArchiveModalOpen = () => {
    setIsArchivingModalOpen(true);
  };

  const handleArchiveModalClose = () => {
    setIsArchivingModalOpen(false);
  };

  const handleArchive = () => {
    const { archiveTheme } = props;
    archiveTheme(id, courseId);
  };

  const handleUnarchive = () => {
    const {
      unarchiveTheme,
    } = props;
    unarchiveTheme(id, courseId, topicId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.updateThemeTitle(id, newTitle, props.courseId);
    setIsEditingTitle(false);
  };

  const saveInSessionStorage = (newProperties) => {
    const currentAccordionStatus = JSON.parse(window.sessionStorage.getItem(`theme_accordion_open_id_${props.id}`));
    const newAccordionStatus = { ...currentAccordionStatus, ...newProperties };
    window.sessionStorage.setItem(`theme_accordion_open_id_${props.id}`, JSON.stringify(newAccordionStatus));
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    saveInSessionStorage({ isOpen: !isOpen });
  };

  const activeLessons = lessons.filter(lesson => lesson.archived === false);

  const lessonsToDisplay = hideArchived ? activeLessons : lessons;
  lessonsToDisplay.forEach((l, i) => { l.position = i + 1; });

  const isTarget = canDrop && isOver;
  const opacity = isDragging ? 0.2 : 1;
  // no background color if target, white if target
  const backgroundColor = isTarget ? '#FFF' : '';
  const underline = isTarget ? { borderBottom: '40px solid #5CB85C' } : {};
  const editStyle = { cursor: 'pointer' };

  drag(drop(ref));

  if (fake) {
    const listStyle = { minHeight: '12px' };
    const handleStyle = { padding: 0, border: 'none', borderBottom: 'none' };
    // eslint-disable-next-line function-call-argument-newline
    return (
      <li
        ref={ref}
        className="dd-item"
        style={{
          backgroundColor, opacity, ...listStyle, ...underline,
        }}
      >
        <div className="dd-handle" style={{ ...handleStyle }} />
      </li>
    );
  }

  // eslint-disable-next-line function-call-argument-newline
  return (
    <li ref={ref} className="dd-item" style={{ backgroundColor, opacity, ...underline }}>
      <div className={'dd-handle' + (archived ? ' archived' : '')}>
        {!locked ? (
          <>
            <span className="pull-left">
              <Button className="accordion-toggle theme-toggle large-gap ml-6" size="xxs" onClick={toggleAccordion}>
                <FontAwesome name={isOpen ? 'chevron-down' : 'chevron-right'} />
              </Button>
            </span>
            <span className="pull-right">
              <a href={`${routes.rootUrl}master_lessons/new?theme_id=${id}`} data-turbolinks="false">
                <Button color="primary" size="xxs" disabled={dirty}>
                  {id < 0 ? 'Save course to add master lesson' : 'New Master Lesson'}
                </Button>
              </a>{' '}
              { archived && <Button color="danger" size="xxs" onClick={handleUnarchive}>Unarchive</Button> }
              { !archived && <Button color="danger" size="xxs" onClick={handleArchiveModalOpen}>Archive</Button> }
              <ToggleAccessButton freeAccess={freeAccess} onToggle={() => toggleThemeAccess(id, courseId, !freeAccess)} />
            </span>
          </>
        ) : (
          <span className="pull-left">
            <Button className="accordion-toggle large-gap" size="xxs" onClick={toggleAccordion}>
              <FontAwesome name={isOpen ? 'chevron-down' : 'chevron-right'} />
            </Button>
          </span>
        )}
        {isEditingTitle ? (
          <Form inline onSubmit={handleSubmit}>
            <FormGroup>
              <Input value={newTitle} onChange={handleChange} size="xs" />
            </FormGroup>
            <Button color="primary ml-1" size="xs">
              <FontAwesome name="check" /> Set
            </Button>
          </Form>
        ) : (
          <span>
            {title}{' '}
            {
                !locked && (
                  <FontAwesome
                    name="pencil"
                    style={{ ...editStyle }}
                    onClick={handleClick}
                  />
                )
              }
          </span>
        )}
        { isArchiveModalOpen && (
        <div className="modal-wrapper">
          <ArchiveConfirmationModal
            entityTitle={title}
            entityType="theme"
            closeModal={handleArchiveModalClose}
            onArchive={handleArchive}
          />
        </div>
        )}
      </div>
      {isOpen && (
        <ol className="dd-list">
          <Lesson
            key={0}
            index={-1}
            themeId={id}
            courseId={courseId}
            fake
            locked={locked}
          />
          {
          Object.keys(lessonsToDisplay).map((key, i) => {
            const lesson = lessonsToDisplay[key];
            const masterLesson = find(masterLessons, ['id', lesson.masterLessonId]);
            return (
              <Lesson
                key={lesson.id}
                id={lesson.id}
                index={i}
                archived={lesson.archived}
                category={masterLesson.category}
                difficulty={masterLesson.difficulty}
                position={lesson.position}
                title={masterLesson.title}
                themeId={lesson.themeId}
                courseId={courseId}
                dirty={dirty}
                locked={locked}
                masterLessonId={masterLesson.id}
                associatedCourses={masterLesson.associatedCourses}
                freeAccess={lesson.freeAccess}
                topicId={topicId}
              />
            );
          })
        }
        </ol>
      )}
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  lessons: getLessonsByTheme(state, ownProps.id),
  masterLessons: masterLessonSelectors.getMasterLessonsByTheme(state, ownProps.id),
  routes: state.routes,
});

export default (flow(connect(mapStateToProps, actions),
)(Theme));
