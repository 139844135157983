// 
/* eslint-disable camelcase */
// @flow
import axios from 'axios';

export const getForumQuestions = () => axios.get('/questions?format=json').catch((e) => { throw e.response; });
export const getForumQuestionsForConversations = () => axios.get('/questions?conversations=true&format=json').catch((e) => { throw e.response; });
export const getForumQuestionsByLesson = (id) => (
  axios.get(`/questions?master_lesson_id=${id}&format=json`).catch((e) => { throw e.response; })
);
export const getForumQuestionsByTheacher = (id) => (
  axios.get(`/questions?teacher_id=${id}&format=json`).catch((e) => { throw e.response; })
);
export const getForumQuestionsByCourse = (id) => (
  axios.get(`/questions?course_id=${id}&format=json`).catch((e) => { throw e.response; })
);
export const postForumQuestion = (question, trix_attachments_data) => (
  axios.post('/questions/?format=json', { question, trix_attachments_data }).catch((e) => { throw e.response; })
);
export const postToggleFollowQuestion = (id) => (
  axios.post(`/questions/${id}/toggle_follow?format=json`).catch((e) => { throw e.response; })
);
export const postVoteForQuestion = (id, type) => (
  axios.post(`/questions/${id}/vote?format=json`, { type }).catch((e) => { throw e.response; })
);
export const postToggleTeacherReview = (id) => (
  axios.patch(`/questions/${id}/toggle_teacher_review?format=json`).catch((e) => { throw e.response; })
);
export const markForumQuestionAsSolved = (id) => (
  axios.patch(`/questions/${id}/mark_as_solved?format=json`).catch((e) => { throw e.response; })
);
