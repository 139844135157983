// 
// @flow
import React, { useState, useRef } from 'react';
import flow from 'lodash/flow';
import { useDrag, useDrop } from 'react-dnd';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import {
  Button, Form, FormGroup,
  Input,
} from 'reactstrap';

import * as util from '../../utilities';
import { TOPIC } from '../constants';
import { actions, getThemesByTopic } from '../../ducks/courseBuilder';
import Theme from './Theme';
import ArchiveConfirmationModal from './ArchiveConfirmationModal';
import ToggleAccessButton from './ToggleAccessButton';



const Topic = ({
  title, id, index, courseId, archived, freeAccess, dirty, fake, locked, themes,
  moveTopic, copyTopic, archiveTopic, unarchiveTopic, newTheme, updateTopicTitle, toggleTopicAccess, hideArchived,
}) => {
  const [isArchiveModalOpen, setIsArchivingModalOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(title === undefined);
  const [newTitle, setNewTitle] = useState(title || '');
  const [isOpen, setIsOpen] = useState((window.sessionStorage.getItem(`topic_accordion_open_id_${id}`)
  && JSON.parse(window.sessionStorage.getItem(`topic_accordion_open_id_${id}`)).isOpen)
    ? JSON.parse(window.sessionStorage.getItem(`topic_accordion_open_id_${id}`)).isOpen
    : false);

  const ref = useRef(null); // Initialize the reference

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: TOPIC,
      drag() {
        return {
          id,
          index,
          courseId,
          type: TOPIC,
          themes: Object.keys(themes).map(key => themes[key].id),
        };
      },
      item: {
        id, courseId, index, themes: Object.keys(themes).map(key => themes[key].id), type: TOPIC,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [index],
  );

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: TOPIC,
      drop(item) {
        if (index === item.index) return;
        if (item.courseId === courseId) {
          const indexChange = index - item.index;
          moveTopic(item.id, indexChange, courseId, item.courseId);
        } else {
          const newTopicId = util.getRandomInt();
          const newThemesStartId = util.getRandomInt();
          copyTopic(item.id, item.themes, newTopicId, newThemesStartId, index, courseId);
        }

        return {
          type: item.type,
          courseId: item.courseId,
        };
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [index],
  );
  const isTarget = canDrop && isOver;
  const opacity = isDragging ? 0.2 : 1;
  const backgroundColor = isTarget ? '#FFF' : '';
  const underline = isTarget ? { borderBottom: '40px solid #5CB85C' } : {};
  const editStyle = { cursor: 'pointer' };

  const handleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleClick = () => {
    setIsEditingTitle(true);
  };

  const handleArchiveModalOpen = () => {
    setIsArchivingModalOpen(true);
  };

  const handleArchiveModalClose = () => {
    setIsArchivingModalOpen(false);
  };

  const handleArchive = () => {
    archiveTopic(id, courseId);
  };

  const handleUnarchive = () => {
    unarchiveTopic(id, courseId);
  };

  const saveInSessionStorage = (newProperties) => {
    const currentAccordionStatus = JSON.parse(window.sessionStorage.getItem(`topic_accordion_open_id_${id}`));
    const newAccordionStatus = { ...currentAccordionStatus, ...newProperties };
    window.sessionStorage.setItem(`topic_accordion_open_id_${id}`, JSON.stringify(newAccordionStatus));
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    saveInSessionStorage({ isOpen: !isOpen });
  };

  const handleNewTheme = (e) => {
    e.preventDefault();
    newTheme(id, courseId);
    if (!isOpen) toggleAccordion();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateTopicTitle(id, newTitle, courseId);
    setIsEditingTitle(false);
  };

  const activeThemes = themes.filter(theme => theme.archived === false);
  const themesToDisplay = hideArchived ? activeThemes : themes;
  themesToDisplay.forEach((t, i) => { t.position = i + 1; });

  drag(drop(ref));

  if (fake) {
    const listStyle = { minHeight: '12px' };
    const handleStyle = { padding: 0, border: 'none', borderBottom: 'none' };
    // eslint-disable-next-line function-call-argument-newline
    return (
      <li
        ref={ref}
        className="dd-item"
        style={{
          backgroundColor, opacity, ...listStyle, ...underline,
        }}
      >
        <div className="dd-handle" style={{ ...handleStyle }} />
      </li>
    );
  }

  // eslint-disable-next-line function-call-argument-newline
  return (
    <li ref={ref} className="dd-item" style={{ backgroundColor, opacity, ...underline }}>
      <div className={'dd-handle topic-item' + (archived ? ' archived' : '')}>
        {!locked ? (
          <>
            <span className="pull-left">
              <Button className="accordion-toggle small-gap" size="xxs" onClick={toggleAccordion}>
                <FontAwesome name={isOpen ? 'chevron-down' : 'chevron-right'} />
              </Button>
            </span>
            <span className="pull-right">
              <Button color="primary" size="xxs" onClick={handleNewTheme}>New Subchapter</Button>{' '}
              { archived && <Button color="danger" size="xxs" onClick={handleUnarchive}>Unarchive</Button> }
              { !archived && <Button color="danger" size="xxs" onClick={handleArchiveModalOpen}>Archive</Button> }
              <ToggleAccessButton freeAccess={freeAccess} onToggle={() => toggleTopicAccess(id, courseId, !freeAccess)} />
            </span>
          </>
        ) : (
          <span className="pull-left">
            <Button className="accordion-toggle small-gap" size="xxs" onClick={toggleAccordion}>
              <FontAwesome name={isOpen ? 'chevron-down' : 'chevron-right'} />
            </Button>
          </span>
        )}
        {isEditingTitle ? (
          <Form inline onSubmit={handleSubmit}>
            <FormGroup>
              <Input value={newTitle} onChange={handleChange} size="xs" />
            </FormGroup>
            <Button color="primary ml-1" size="xs">
              <FontAwesome name="check" /> Set
            </Button>
          </Form>
        ) : (
          <span>
            {title}{' '}
            {
              !locked && (
                <FontAwesome
                  name="pencil"
                  style={{ ...editStyle }}
                  onClick={handleClick}
                />
              )
            }
          </span>
        )}
        { isArchiveModalOpen && (
          <div className="modal-wrapper">
            <ArchiveConfirmationModal
              entityTitle={title}
              entityType="topic"
              closeModal={handleArchiveModalClose}
              onArchive={handleArchive}
            />
          </div>
        )}
      </div>
      {isOpen && (
      <ol className="dd-list">
        {/* $FlowFixMe */}
        <Theme
          key={0}
          index={-1}
          topicId={id}
          courseId={courseId}
          fake
          locked={locked}
        />
        {
          themesToDisplay && Object.keys(themesToDisplay).map((key, i) => {
            const theme = themesToDisplay[key];
            return (
              <Theme
                key={theme.id}
                id={theme.id}
                index={i}
                archived={theme.archived}
                difficulty={theme.difficulty}
                position={theme.position}
                title={theme.title}
                topicId={id}
                courseId={courseId}
                dirty={dirty}
                locked={locked}
                freeAccess={theme.freeAccess}
                hideArchived={hideArchived}
              />
            );
          })
        }
      </ol>
      )}
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  themes: getThemesByTopic(state, ownProps.id),
  routes: state.routes,
});

export default (flow(connect(mapStateToProps, actions),
)(Topic));
