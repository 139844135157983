// 
// @flow
import React from 'react';
import { filter } from 'lodash';
import MultiSelectorContainer from './MultiSelectorContainer';



class MultiSelectorConditionalContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedOption: props.previouslySelectedOption || 'include_selected',
    };
  }

  handleOptionSelect = (e) => {
    this.setState({ selectedOption: e.currentTarget.value });
  };

  render() {
    const { inputVariableName, itemsAvailable, optionVariableName } = this.props;
    let { previouslySelectedItemsIds } = this.props;

    if (this.state.selectedOption === 'exclude_selected') {
      const previouslySelectedItems = filter(itemsAvailable, item => !previouslySelectedItemsIds?.some(id => item.id.toString() === id));

      previouslySelectedItemsIds = previouslySelectedItems.map(item => item.id.toString());
    }

    return (
      <>
        <div className="d-flex mb-2">
          <div className="mr-4 d-flex align-items-center">
            <label htmlFor='include_selected' className="mr-1 mb-0">Include selected</label>
            <input
              type='radio'
              id='include_selected'
              value='include_selected'
              checked={this.state.selectedOption === 'include_selected'}
              onChange={this.handleOptionSelect}
            /><br />
          </div>
          <div className="mr-4 d-flex align-items-center">
            <label htmlFor='exclude_selected' className="mr-1 mb-0">Exclude selected</label>
            <input
              type='radio'
              id='exclude_selected'
              value='exclude_selected'
              checked={this.state.selectedOption === 'exclude_selected'}
              onChange={this.handleOptionSelect}
            /><br />
          </div>
          <div className="d-flex align-items-center">
            <label htmlFor='include_all' className="mr-1 mb-0">Include all</label>
            <input
              type='radio'
              id='include_all'
              value='include_all'
              checked={this.state.selectedOption === 'include_all'}
              onChange={this.handleOptionSelect}
            />
          </div>
        </div>
        <div>
          <input type='hidden' name={optionVariableName} value={this.state.selectedOption} />
        </div>
        <MultiSelectorContainer
          itemsAvailable={itemsAvailable}
          inputVariableName={inputVariableName}
          previouslySelectedItemsIds={previouslySelectedItemsIds}
          isDisabled={this.state.selectedOption === 'include_all'}
        />
      </>
    );
  }
}
export default (MultiSelectorConditionalContainer);
