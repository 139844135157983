// 
// @flow
import React, { Component, } from 'react';
import { filter, orderBy, values } from 'lodash';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import {
  isAfter, parseISO, subDays, differenceInSeconds,
} from 'date-fns';
import CourseDiscussionsTable from './CourseDiscussionsTable';
import forumActions from '../../redux/discussions/actions';
import { subscribeToLastChange } from '../../api/notificationsApi';
import { selectors as questionsSelectors } from '../../ducks/forumQuestions';
import CourseDiscussionsCoursesList from './CourseDiscussionsCoursesList';
import { timeFromNow } from '../../utilities/date';



class CourseDiscussions extends Component {
  state = {
    sectionType: 'forum',
    lastChange: null,
    isInitialLoad: null,
  };
  
  checkFirebaseInitialization = () => {
    if (firebase.apps.length) {
      console.log('JEDAN')
      this.setState({ isInitialLoad: true });
      subscribeToLastChange((date) => this.checkLastChange(date));
    } else {
      console.log('DVA')
      setTimeout(this.checkFirebaseInitialization, 500); // Check again after a delay
    }
  }

  componentDidMount() {
    // this.setState({ isInitialLoad: true });
    // subscribeToLastChange((date: string) => this.checkLastChange(date));
    this.checkFirebaseInitialization();
  }

  checkLastChange = (timeOfChange) => {
    const lastChange = this.state.lastChange ? parseISO(this.state.lastChange) : subDays(new Date(), 1);
    if (timeOfChange && isAfter(parseISO(timeOfChange), lastChange)) {
      this.setState({ lastChange: timeOfChange, isInitialLoad: false });
      this.getChanges();
    }
  };

  filterType = (questions, sectionType) => (
    filter(questions, item => {
      if (sectionType === 'forum') {
        return item.masterLessonId === null;
      }

      return !!item.masterLessonId;
    })
  );

  getCourseQuestions = (courseId, action) => {
    const { allQuestions } = this.props;
    const courseQuestionsArray = [];

    filter(values(allQuestions), item => {
      if (item.courseId === courseId) {
        courseQuestionsArray.push(item);
      }
    });

    const getLastCommentIndex = (questionsArray) => {
      const temp = questionsArray.map(item => Math.abs(new Date() - new Date(item.lastCommentedAt).getTime()));
      const idx = temp.indexOf(Math.min(...temp));
      return idx;
    };

    if (courseQuestionsArray.length && action === 'getLatestCommentDate') {
      const idx = getLastCommentIndex(courseQuestionsArray);
      return timeFromNow(courseQuestionsArray[idx].lastCommentedAt);
    } else if (!courseQuestionsArray.length && action === 'getLatestCommentDate') {
      return 'Ingen aktivitet';
    }

    if (courseQuestionsArray.length && action === 'getLatestCommentFromNowInMinutes') {
      const idx = getLastCommentIndex(courseQuestionsArray);
      return differenceInSeconds(new Date(), parseISO(courseQuestionsArray[idx].lastCommentedAt));
    } else if (!courseQuestionsArray.length && action === 'getLatestCommentFromNowInMinutes') {
      return null;
    }

    if (action === 'getCommentNumberInfo') {
      let count = 0;
      courseQuestionsArray.map(item => (item.totalNumberOfComments === 0 && !item.markedAsSolved && count++));
      return count;
    }

    if (action === 'getLastAnswerStatus') {
      let count = 0;
      courseQuestionsArray.map(item => (
        item.totalNumberOfComments > 0
        && !item.markedAsSolved
        && item.lastAnswerInComment.answererType === 'Student'
        && !item.lastAnswerInComment.postedBySuperaccessStudent
        && count++
      ));
      return count;
    }

    if (action === 'getCourseStatus') {
      let lastAnswerCount = 0;
      let numberOfCommentsCount = 0;
      courseQuestionsArray.map(item => (
        item.totalNumberOfComments > 0
        && !item.markedAsSolved
        && item.lastAnswerInComment.answererType === 'Student'
        && !item.lastAnswerInComment.postedBySuperaccessStudent
        && lastAnswerCount++
      ));
      courseQuestionsArray.map(item => (!item.markedAsSolved && item.totalNumberOfComments === 0 && numberOfCommentsCount++));

      if (lastAnswerCount > 0 || numberOfCommentsCount > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  getChanges = () => {
    if (!this.state.isInitialLoad) {
      this.props.getForumQuestionsForConversations();
    }
  };

  handleSolveThreadClick = (questionId) => {
    this.props.markForumQuestionAsSolved(questionId);
  };

  render() {
    const { sectionType } = this.state;
    const {
      questions, selectedCourse,
      goToCourseForumOverview,
      courses, onSelectCourse,
    } = this.props;

    const questionsToDisplay = this.filterType(questions, sectionType);

    const noCourseSelected = selectedCourse === null;

    return (
      <div className="course-discussions">
        {noCourseSelected ? (
          <CourseDiscussionsCoursesList
            onSelectCourse={onSelectCourse}
            courses={courses}
            discussions={orderBy(questionsToDisplay, ['lastCommentedAt'], ['desc'])}
            getCourseQuestions={this.getCourseQuestions}
          />
        ) : (
          <>
            <div className="discussions-header">
              <div className="title-item">
                <div className="back-button" onClick={goToCourseForumOverview}>
                  <FontAwesome name="chevron-left" /> Tilbake til oversikt forum
                </div>
              </div>
              <div className="title">{selectedCourse.title}</div>
              <div className="title-item">
                {/* Placeholder */}
              </div>
            </div>
            {questions && (
              <CourseDiscussionsTable
                discussions={orderBy(questionsToDisplay, ['lastCommentedAt'], ['desc'])}
                goToCourseForumOverview={goToCourseForumOverview}
                onSolveThreadClick={this.handleSolveThreadClick}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  allQuestions: questionsSelectors.getForumQuestions(state),
  questions: ownProps.selectedCourse ? questionsSelectors.getForumQuestionsByCourseId(state, ownProps.selectedCourse.id) : null,
});

export default (connect(
  mapStateToProps, { ...forumActions },
)(CourseDiscussions));
