// 
// @flow
import React from 'react';
import { Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import checkboxImage from '../../../../../assets/images/icons/check-white.svg';



class ArchiveConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  // we changed naming of topic and theme to chapter
  // and subchapter but still want to use old naming in the code
  entityTypeMapping = {
    topic: 'chapter',
    theme: 'subchapter',
    lesson: 'lesson',
  };

  entityTypeName = (entityType) => this.entityTypeMapping[this.props.entityType] || entityType; // Return the mapped name or the original name if no mapping is found

  render() {
    const { entityTitle, closeModal } = this.props;
    const { checked } = this.state;

    return (
      <div className="modal-box">
        <div className="archive-confirmation-modal-wrapper">
          <div className="archive-modal-header">
            <h5 className="modal-title">{`Are you sure you want to archive this ${this.entityTypeName()}:`}</h5>
            <div className="close-btn" onClick={closeModal}>
              <FontAwesome name="close" />
            </div>
          </div>
          <div className="prompt">
            <h5 className="entity-title">{entityTitle}</h5>
            <div className="checkbox-wrapper">
              <div className={checked ? 'checkbox checked' : 'checkbox'} onClick={this.toggleChecked}>
                {checked ? <img src={checkboxImage} alt="" /> : ''}
              </div>
              {`Yes, I want to archive this ${this.entityTypeName(entityTitle)}`.toUpperCase()}
            </div>
          </div>
          <div className="actions-wrapper">
            <Button
              block
              className={'btn delete p-3 px-4' + (checked ? '' : ' disabled')}
              onClick={this.handleArchive}
              outline
            >
              Archive
            </Button>
            <Button
              block
              className="btn clone p-3 px-4"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }

  handleArchive = () => {
    const { onArchive, closeModal } = this.props;
    const { checked } = this.state;
    if (checked) {
      onArchive();
      closeModal();
    }
  };

  handleCancel = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };
}

export default (ArchiveConfirmationModal);
