// 
// @flow
import
{
  includes, filter, forEach, pickBy,
} from 'lodash';

export const types = {
  GET_FORUM_QUESTIONS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS',
  GET_FORUM_QUESTIONS_SUCCESS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_SUCCESS',
  GET_FORUM_QUESTIONS_FAILURE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_FAILURE',
  GET_FORUM_QUESTIONS_FOR_CONVERSATIONS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_FOR_CONVERSATIONS',
  GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_SUCCESS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_SUCCESS',
  GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_FAILURE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_FOR_CONVERSATIONS_FAILURE',
  GET_FORUM_QUESTIONS_BY_MASTER_LESSON: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_MASTER_LESSON',
  GET_FORUM_QUESTIONS_BY_MASTER_LESSON_SUCCESS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_MASTER_LESSON_SUCCESS',
  GET_FORUM_QUESTIONS_BY_MASTER_LESSON_FAILURE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_MASTER_LESSON_FAILURE',
  GET_FORUM_QUESTIONS_BY_TEACHER: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_TEACHER',
  GET_FORUM_QUESTIONS_BY_TEACHER_SUCCESS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_TEACHER_SUCCESS',
  GET_FORUM_QUESTIONS_BY_TEACHER_FAILURE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_TEACHER_FAILURE',
  GET_FORUM_QUESTIONS_BY_COURSE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_COURSE',
  GET_FORUM_QUESTIONS_BY_COURSE_SUCCESS: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_COURSE_SUCCESS',
  GET_FORUM_QUESTIONS_BY_COURSE_FAILURE: 'FORUM/QUESTIONS/GET_FORUM_QUESTIONS_BY_COURSE_FAILURE',
  POST_FORUM_QUESTION: 'FORUM/QUESTIONS/POST_FORUM_QUESTION',
  POST_FORUM_QUESTION_SUCCESS: 'FORUM/QUESTIONS/POST_FORUM_QUESTION_SUCCESS',
  POST_FORUM_QUESTION_FAILURE: 'FORUM/QUESTIONS/POST_FORUM_QUESTION_FAILURE',
  UPDATE_FORUM_QUESTION: 'FORUM/QUESTIONS/UPDATE_FORUM_QUESTION',
  UPDATE_FORUM_QUESTION_SUCCESS: 'FORUM/QUESTIONS/UPDATE_FORUM_QUESTION_SUCCESS',
  UPDATE_FORUM_QUESTION_FAILURE: 'FORUM/QUESTIONS/UPDATE_FORUM_QUESTION_FAILURE',
  MARK_FORUM_QUESTION_AS_SOLVED: 'FORUM/QUESIONS/MARK_FORUM_QUESTION_AS_SOLVED',
  MARK_FORUM_QUESTION_AS_SOLVED_SUCCESS: 'FORUM/QUESIONS/MARK_FORUM_QUESTION_AS_SOLVED_SUCCESS',
  MARK_FORUM_QUESTION_AS_SOLVED_FAILURE: 'FORUM/QUESIONS/MARK_FORUM_QUESTION_AS_SOLVED_FAILURE',
};

export default (state = {}, action) => {
  if (includes(action.type, 'QUESTIONS/GET_FORUM') && includes(action.type, 'SUCCESS')) {
    state = {};
  }

  if (action.type === 'STUDENT/CHANGE_PROFILE_PICTURE_PERMISSION_SUCCESS') {
    let profilePicture = null;
    if (action.displayProfilePicture === true) {
      profilePicture = action.profilePictureUrl;
    }
    const ownedQuestions = {};
    forEach(state, (value, key) => {
      if (value.ownerId === action.studentId) {
        value.ownerPicture = profilePicture;
        ownedQuestions[key] = value;
      }
    });
    return { ...state, ...ownedQuestions };
  }

  if (action.type === 'FORUM/QUESTIONS/DELETE_FORUM_QUESTION_SUCCESS') {
    return pickBy(state, (value) => value.id !== action.questionId);
  }

  if (action.entities && action.entities.forumQuestions) {
    return {
      ...state,
      ...action.entities.forumQuestions,
    };
  }

  return state;
};

export const actions = {
  getForumQuestions: () => ({ type: types.GET_FORUM_QUESTIONS }),
  getForumQuestionsForConversations: () => ({ type: types.GET_FORUM_QUESTIONS_FOR_CONVERSATIONS }),
  getForumQuestionsByTheacher: (id) => ({ type: types.GET_FORUM_QUESTIONS_BY_TEACHER, id }),
  markForumQuestionAsSolved: (id) => ({ type: types.MARK_FORUM_QUESTION_AS_SOLVED, id }),
};

// Simple selectors
export const selectors = {
  getForumQuestions: (state) => state.entities.forumQuestions,
  getForumQuestionsByCourseId: (state, id) => (
    filter(state.entities.forumQuestions, q => q.courseId === id)
  ),
};
