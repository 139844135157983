// 
// @flow
/* eslint no-console: 0 */
import React, { Component } from 'react';
import axios from 'axios';
import autoBind from 'react-autobind';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Input, Row, Col } from 'reactstrap';

import { actions } from '../ducks/courseBuilder';
import Course from './Course/Course';
import LessonsList from './Course/LessonsList';



class CourseBuilder extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      masterLessonSearchTitle: '',
      selectorType: 'course',
    };
  }

  getOptions = (input) => {
    const { rootUrl, searchCoursesPath } = this.props.routes;
    return axios.get(`${rootUrl.slice(0, -1)}${searchCoursesPath}?title=${input}`)
      .then((response) => {
        const data = Object.keys(response.data).map(key => ({
          value: response.data[key].id,
          label: response.data[key].title,
        }));
        return data;
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  setSourceCourse = (val) => {
    this.props.pickSourceCourse(val.value);
  };

  searchForMasterLessons = (event) => {
    this.setState({ masterLessonSearchTitle: event.target.value });
    this.props.searchForMasterLessons(event.target.value);
  };

  handleSelectorTypeChange = (val) => {
    this.setState({ selectorType: val.value });
  };

  render() {
    const {
      authenticityToken, sourceCourseId, sourceCourseIsLoading, targetCourseId,
    } = this.props;
    const { masterLessonSearchTitle, selectorType } = this.state;
    const selectorTypes = [
      { value: 'course', label: 'Course' },
      { value: 'masterLesson', label: 'Master Lesson' },
    ];
    const selectedValue = selectorTypes.find(s => s.value.toLowerCase() === selectorType.toLowerCase());
    const customStyles = {
      indicatorSeparator: () => ({
        opacity: 0,
      }),
    };

    return (
      <DndProvider backend={HTML5Backend}>
        <Row>
          <Col xs="6" className="mb-1">
            <p>Search by:</p>
            <Select
              styles={customStyles}
              name="selectortype"
              isClearable={false}
              isSearchable={false}
              options={selectorTypes}
              onChange={this.handleSelectorTypeChange}
              value={selectedValue}
              isDisabled={sourceCourseIsLoading}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            {
              selectorType === 'course' ? (
                <AsyncSelect
                  styles={customStyles}
                  name="source-course"
                  isClearable={false}
                  loadOptions={this.getOptions}
                  onChange={this.setSourceCourse}
                  ignoreAccents={false}
                  isDisabled={sourceCourseIsLoading}
                  cacheOptions
                  defaultOptions
                />
              ) : (
                <Input
                  name="master-lessons"
                  onChange={this.searchForMasterLessons}
                  value={masterLessonSearchTitle}
                />
              )
            }
            <br />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs="12">
            {
              selectorType === 'course' ? (
                <Course id={sourceCourseId} locked />
              ) : (
                <LessonsList />
              )
            }
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Course authenticityToken={authenticityToken} id={targetCourseId} />
          </Col>
        </Row>
      </DndProvider>
    );
  }
}

const mapStateToProps = state => ({
  routes: state.routes,
  sourceCourseId: state.sourceCourseId,
  sourceCourseIsLoading: state.sourceCourseIsLoading,
  targetCourseId: state.targetCourseId,
});

export default (connect(mapStateToProps, actions)(CourseBuilder));
