// 
// @flow
// http://blog.sapegin.me/all/react-structure
// https://hackernoon.com/my-journey-toward-a-maintainable-project-structure-for-react-redux-b05dfd999b5
// Entities flat structure:
// https://github.com/reactjs/redux/issues/994#issuecomment-153341165
/* eslint default-case: 0 */
import {
  find, findLast, merge, sortBy, mapValues,
} from 'lodash';
import { combineReducers } from 'redux';

import { getRandomInt } from '../utilities';

const ADD_LESSON_FROM_MASTER_LESSON = 'ADD_LESSON_FROM_MASTER_LESSON';
const COPY_TOPIC = 'COPY_TOPIC';
const COPY_THEME = 'COPY_THEME';
const COPY_LESSON = 'COPY_LESSON';
const ARCHIVE_TOPIC = 'ARCHIVE_TOPIC';
const ARCHIVE_THEME = 'ARCHIVE_THEME';
const ARCHIVE_LESSON = 'ARCHIVE_LESSON';
const UNARCHIVE_TOPIC = 'UNARCHIVE_TOPIC';
const UNARCHIVE_THEME = 'UNARCHIVE_THEME';
const UNARCHIVE_LESSON = 'UNARCHIVE_LESSON';
const FINISH_LOADING_COURSE = 'FINISH_LOADING_COURSE';
const FINISH_SEARCH_FOR_MASTER_LESSONS = 'FINISH_SEARCH_FOR_MASTER_LESSONS';
const LOAD_COURSE_FROM_DATA = 'LOAD_COURSE_FROM_DATA';
const LOAD_MASTER_LESSONS_FROM_DATA = 'LOAD_MASTER_LESSONS_FROM_DATA';
const MOVE_TOPIC = 'MOVE_TOPIC';
const MOVE_THEME = 'MOVE_THEME';
const MOVE_LESSON = 'MOVE_LESSON';
const NEW_TOPIC = 'NEW_TOPIC';
const NEW_THEME = 'NEW_THEME';
const SAVE_COURSE = 'SAVE_COURSE';
const SAVE_COURSE_FAILED = 'SAVE_COURSE_FAILED';
const SAVE_COURSE_PROCESSING = 'SAVE_COURSE_PROCESSING';
const SAVE_COURSE_SUCCEEDED = 'SAVE_COURSE_SUCCEEDED';
const SEARCH_FOR_MASTER_LESSONS = 'SEARCH_FOR_MASTER_LESSONS';
const SET_SOURCE_COURSE = 'SET_SOURCE_COURSE';
const START_LOADING_COURSE = 'START_LOADING_COURSE';
const START_SEARCH_FOR_MASTER_LESSONS = 'START_SEARCH_FOR_MASTER_LESSONS';
const PICK_SOURCE_COURSE = 'PICK_SOURCE_COURSE';
const UPDATE_TOPIC_TITLE = 'UPDATE_TOPIC_TITLE';
const UPDATE_THEME_TITLE = 'UPDATE_THEME_TITLE';
const UPDATE_MASTER_LESSON_TITLE = 'UPDATE_MASTER_LESSON_TITLE';
const TOGGLE_LESSON_ACCESS = 'TOGGLE_LESSON_ACCESS';
const TOGGLE_TOPIC_ACCESS = 'TOGGLE_TOPIC_ACCESS';
const TOGGLE_THEME_ACCESS = 'TOGGLE_THEME_ACCESS';

// Actions

export const actions = {
  addLessonFromMasterLesson: (newId, index, themeId, topicId, courseId, masterLessonId, masterLesson) => ({
    type: ADD_LESSON_FROM_MASTER_LESSON, newId, index: index + 1.5, themeId, topicId, courseId, masterLessonId, masterLesson,
  }),
  copyTopic: (id, sourceThemeIds, newId, newThemesStartId, index, courseId) => ({
    type: COPY_TOPIC,
    id,
    sourceThemeIds,
    newId,
    newThemesStartId,
    index: index + 1.5,
    courseId,
  }),
  copyTheme: (id, newId, index, topicId, courseId) => (
    {
      type: COPY_THEME, id, newId, index: index + 1.5, topicId, courseId,
    }
  ),
  copyLesson: (id, newId, index, themeId, topicId, courseId) => (
    {
      type: COPY_LESSON, id, newId, index: index + 1.5, themeId, topicId, courseId,
    }
  ),
  archiveTopic: (id, courseId) => ({ type: ARCHIVE_TOPIC, id, courseId }),
  archiveTheme: (id, courseId) => ({ type: ARCHIVE_THEME, id, courseId }),
  archiveLesson: (id, courseId) => ({ type: ARCHIVE_LESSON, id, courseId }),
  unarchiveTopic: (id, courseId) => ({ type: UNARCHIVE_TOPIC, id, courseId }),
  unarchiveTheme: (id, courseId, topicId) => ({
    type: UNARCHIVE_THEME, id, courseId, topicId,
  }),
  unarchiveLesson: (id, courseId, topicId, themeId) => ({
    type: UNARCHIVE_LESSON, id, courseId, topicId, themeId,
  }),
  finishLoadingCourse: (courseId) => ({ type: FINISH_LOADING_COURSE, courseId }),
  finishSearchForMasterLessons: (matchingMasterLessonIds) => ({
    type: FINISH_SEARCH_FOR_MASTER_LESSONS, matchingMasterLessonIds,
  }),
  loadCourseFromData: (entities) => ({ type: LOAD_COURSE_FROM_DATA, entities }),
  loadMasterLessonsFromData: (entities) => ({ type: LOAD_MASTER_LESSONS_FROM_DATA, entities }),
  moveTopic: (id, indexChange, courseId, prevCourseId) => ({
    type: MOVE_TOPIC, id, indexChange, courseId, prevCourseId,
  }),
  moveTheme: (id, indexChange, topicId, prevTopicId, courseId) => ({
    type: MOVE_THEME, id, indexChange, topicId, prevTopicId, courseId,
  }),
  moveLesson: (id, index, themeId, prevThemeId, courseId) => ({
    type: MOVE_LESSON, id, index: index + 1.5, themeId, prevThemeId, courseId,
  }),
  newTopic: (courseId) => ({ type: NEW_TOPIC, courseId }),
  newTheme: (topicId, courseId) => ({ type: NEW_THEME, topicId, courseId }),
  saveCourse: (courseId, authenticityToken) => ({ type: SAVE_COURSE, courseId, authenticityToken }),
  saveCourseFailed: (courseId, error) => ({ type: SAVE_COURSE_FAILED, courseId, error }),
  saveCourseProcessing: (courseId) => ({ type: SAVE_COURSE_PROCESSING, courseId }),
  saveCourseSucceeded: (courseId) => ({ type: SAVE_COURSE_SUCCEEDED, courseId }),
  searchForMasterLessons: (title) => ({ type: SEARCH_FOR_MASTER_LESSONS, title }),
  setSourceCourse: (courseId) => ({ type: SET_SOURCE_COURSE, courseId }),
  startLoadingCourse: (courseId) => ({ type: START_LOADING_COURSE, courseId }),
  startSearchForMasterLessons: (title) => ({ type: START_SEARCH_FOR_MASTER_LESSONS, title }),
  pickSourceCourse: (courseId) => ({ type: PICK_SOURCE_COURSE, courseId }),
  updateTopicTitle: (id, title, courseId) => ({
    type: UPDATE_TOPIC_TITLE, id, title, courseId,
  }),
  updateThemeTitle: (id, title, courseId) => ({
    type: UPDATE_THEME_TITLE, id, title, courseId,
  }),
  updateMasterLessonTitle: (id, title, courseId) => ({
    type: UPDATE_MASTER_LESSON_TITLE, id, title, courseId,
  }),
  toggleLessonAccess: (id, courseId, nextValue) => ({
    type: TOGGLE_LESSON_ACCESS, id, courseId, nextValue,
  }),
  toggleThemeAccess: (id, courseId, nextValue) => ({
    type: TOGGLE_THEME_ACCESS, id, courseId, nextValue,
  }),
  toggleTopicAccess: (id, courseId, nextValue) => ({
    type: TOGGLE_TOPIC_ACCESS, id, courseId, nextValue,
  }),
};

// Helper functions

const reindex = (object, parentAttribute, parentId) => {
  const keys = Object.keys(object).filter(key => (object[key][parentAttribute] === parentId));
  const sortedKeys = keys.sort((a, b) => object[a].position - object[b].position);
  const newObject = object;
  sortedKeys.forEach((key, i) => { newObject[key].position = i + 1; });
  return newObject;
};

// Selectors

export const getCourseById = (state, courseId) => {
  const courseKey = Object.keys(state.entities.courses).filter(key => (
    state.entities.courses[key].id === courseId
  ));
  return state.entities.courses[courseKey[0]];
};

export const getTopicsByCourse = (state, courseId, includeDeleted = false, includeArchived = false) => {
  const topicKeys = Object.keys(state.entities.topics).filter(key => (
    state.entities.topics[key].courseId === courseId
    && (state.entities.topics[key].archived === false || includeArchived === true || state.isAdmin === true)
    && (state.entities.topics[key].destroy !== true || includeDeleted === true)
  ));
  const sortedTopicKeys = topicKeys.sort(
    (a, b) => state.entities.topics[a].position - state.entities.topics[b].position,
  );
  const topics = sortedTopicKeys.map((key) => state.entities.topics[key]);
  return topics;
};

export const getThemesByTopic = (state, topicId, includeDeleted = false, includeArchived = false) => {
  const themeKeys = Object.keys(state.entities.themes).filter(key => (
    state.entities.themes[key].topicId === topicId
    && (state.entities.themes[key].archived === false || includeArchived === true || state.isAdmin === true)
    && (state.entities.themes[key].destroy !== true || includeDeleted === true)
  ));
  const sortedThemeKeys = themeKeys.sort(
    (a, b) => state.entities.themes[a].position - state.entities.themes[b].position,
  );
  const themes = sortedThemeKeys.map(key => state.entities.themes[key]);
  return themes;
};

export const getLessonsByTopic = (state, topicId, includeDeleted = false, includeArchived = false) => {
  const lessonKeys = Object.keys(state.entities.lessons).filter(key => (
    state.entities.lessons[key].topicId === topicId
    && (state.entities.lessons[key].archived === false || includeArchived === true || state.isAdmin === true)
    && (state.entities.lessons[key].destroy !== true || includeDeleted === true)
  ));
  const sortedLessonKeys = lessonKeys.sort(
    (a, b) => state.entities.lessons[a].position - state.entities.lessons[b].position,
  );
  const lessons = sortedLessonKeys.map(key => state.entities.lessons[key]);
  return lessons;
};

export const getLessonsByTheme = (state, themeId, includeDeleted = false, includeArchived = false) => {
  const lessonKeys = Object.keys(state.entities.lessons).filter(key => (
    state.entities.lessons[key].themeId === themeId
    && (state.entities.lessons[key].archived === false || includeArchived === true || state.isAdmin === true)
    && (state.entities.lessons[key].destroy !== true || includeDeleted === true)
  ));
  const sortedLessonKeys = lessonKeys.sort(
    (a, b) => state.entities.lessons[a].position - state.entities.lessons[b].position,
  );
  const lessons = sortedLessonKeys.map(key => state.entities.lessons[key]);
  return lessons;
};

export const getMatchingMasterLessons = (state) => {
  const keys = Object.keys(state.entities.masterLessons).filter(key => (
    state.matchingMasterLessonIds.includes(state.entities.masterLessons[key].id)
  ));
  const masterLessons = keys.map(key => state.entities.masterLessons[key]);
  return masterLessons;
};

// Reducers

const entityReducers = {
  courses: (state = {}, action) => {
    const dirtyActions = [
      ADD_LESSON_FROM_MASTER_LESSON,
      COPY_TOPIC, COPY_THEME, COPY_LESSON,
      ARCHIVE_TOPIC, ARCHIVE_THEME, ARCHIVE_LESSON,
      UNARCHIVE_TOPIC, UNARCHIVE_THEME, UNARCHIVE_LESSON,
      MOVE_TOPIC, MOVE_THEME, MOVE_LESSON,
      NEW_TOPIC, NEW_THEME,
      UPDATE_TOPIC_TITLE, UPDATE_THEME_TITLE, UPDATE_MASTER_LESSON_TITLE,
      TOGGLE_TOPIC_ACCESS, TOGGLE_THEME_ACCESS, TOGGLE_LESSON_ACCESS];
    if (dirtyActions.includes(action.type)) {
      const { courseId } = action;
      return ({
        ...state,
        [courseId]: {
          ...state[courseId],
          dirty: true,
        },
      });
    }

    switch (action.type) {
      case SAVE_COURSE_PROCESSING: {
        const { courseId } = action;
        return {
          ...state,
          [courseId]: {
            ...state[courseId],
            busy: true,
          },
        };
      }

      case SAVE_COURSE_FAILED: {
        const { courseId, error } = action;
        return {
          ...state,
          [courseId]: {
            ...state[courseId],
            busy: false,
            dirty: false,
            error,
            lastSaveSuccessful: false,
          },
        };
      }

      case SAVE_COURSE_SUCCEEDED: {
        const { courseId } = action;
        return {
          ...state,
          [courseId]: {
            ...state[courseId],
            busy: false,
            dirty: false,
            lastSaveSuccessful: true,
          },
        };
      }
    }

    if (action.entities && action.entities.courses) {
      return merge({}, state, action.entities.courses);
    }
    return state;
  },

  topics: (state = {}, action) => {
    switch (action.type) {
      case COPY_TOPIC: {
        const {
          id, newId, index, courseId,
        } = action;
        let topics = state;
        const newTopic = {
          ...topics[id],
          position: index,
          id: newId,
          courseId,
        };

        topics = {
          ...topics,
          [newId]: newTopic,
        };
        topics = reindex(topics, 'courseId', courseId);

        return topics;
      }

      case UNARCHIVE_LESSON:
      case UNARCHIVE_THEME: {
        const { topicId } = action;
        const topic = state[topicId];
        topic.archived = false;

        return ({
          ...state,
          [topicId]: { ...topic },
        });
      }

      case ARCHIVE_TOPIC: {
        const { id } = action;
        const topic = state[id];
        topic.archived = true;

        return ({
          ...state,
          [id]: { ...topic },
        });
      }

      case UNARCHIVE_TOPIC: {
        const { id } = action;
        const topic = state[id];
        topic.archived = false;

        return ({
          ...state,
          [id]: { ...topic },
        });
      }

      case MOVE_TOPIC: {
        const {
          id, indexChange, courseId, prevCourseId,
        } = action;
        let topics = state;
        topics[id].courseId = courseId;
        const startingPosition = topics[id].position;
        let neighborTopic;

        if (indexChange > 0) { // moving down
          neighborTopic = find(sortBy(topics, 'position'), (topic) => (topic.position > startingPosition) && ((topic.position - startingPosition) >= indexChange));
          if (neighborTopic) {
            topics[id].position = neighborTopic.position + 0.5; // ensures that our topic gets behind the next one
          }
        } else { // moving up
          neighborTopic = findLast(sortBy(topics, 'position'), (topic) => (topic.position < startingPosition) && ((topic.position - startingPosition) <= indexChange));
          if (neighborTopic) {
            topics[id].position = neighborTopic.position - 0.5; // ensures that our topic gets in front of the previous one
          }
        }

        topics = reindex(topics, 'courseId', prevCourseId);
        topics = reindex(topics, 'courseId', courseId);

        return topics;
      }

      case NEW_TOPIC: {
        const { courseId } = action;
        const id = getRandomInt();
        let topics = state;
        topics[id] = {
          id,
          courseId,
          position: 0.5,
          archived: false,
        };
        topics = reindex(topics, 'courseId', courseId);
        return topics;
      }

      case SAVE_COURSE_SUCCEEDED: {
        const topics = state;
        const persistedTopicKeys = Object.keys(topics).filter(key => (
          topics[key].id > 0 && topics[key].destroy !== true
        ));
        const persistedTopics = {};
        persistedTopicKeys.map((key) => { persistedTopics[key] = topics[key]; return key; });
        return persistedTopics;
      }

      case UPDATE_TOPIC_TITLE: {
        const { id, title } = action;
        const topic = state[id];
        topic.title = title;

        return ({
          ...state, // state === topics collection
          [id]: topic,
        });
      }

      case TOGGLE_TOPIC_ACCESS: {
        const { id, nextValue } = action;
        const topic = state[id];
        topic.freeAccess = nextValue;

        return ({
          ...state,
          [id]: topic,
        });
      }

      default:
        if (action.entities && action.entities.topics) {
          return merge({}, state, action.entities.topics);
        }
        return state;
    }
  },

  themes: (state = {}, action) => {
    switch (action.type) {
      case COPY_THEME: {
        const {
          id, newId, index, topicId,
        } = action;
        let themes = state;
        const newTheme = {
          ...themes[id],
          position: index,
          id: newId,
          topicId,
        };

        themes[id].destroy = true;
        themes = {
          ...themes,
          [newId]: newTheme,
        };
        themes = reindex(themes, 'topicId', topicId);

        return themes;
      }

      case COPY_TOPIC: {
        const sourceTopicId = action.id;
        const newTopicId = action.newId;
        const { newThemesStartId } = action;
        const themes = state;
        const selectedThemes = getThemesByTopic({ entities: { themes } }, sourceTopicId);
        const newThemes = {};
        Object.keys(selectedThemes).map((key, index) => {
          // const oldThemeId = selectedThemes[key].id;
          const newThemeId = newThemesStartId + index;
          newThemes[newThemeId] = JSON.parse(JSON.stringify(selectedThemes[key]));
          newThemes[newThemeId].id = newThemeId;
          newThemes[newThemeId].topicId = newTopicId;
          return newThemes[newThemeId];
        });

        return {
          ...themes,
          ...newThemes,
        };
      }

      case UNARCHIVE_LESSON: {
        const { themeId } = action;
        const theme = state[themeId];
        theme.archived = false;

        return ({
          ...state,
          [themeId]: { ...theme },
        });
      }

      case ARCHIVE_THEME: {
        const { id } = action;
        const theme = state[id];
        theme.archived = true;

        return ({
          ...state,
          [id]: { ...theme },
        });
      }

      case UNARCHIVE_THEME: {
        const { id } = action;
        const theme = state[id];
        theme.archived = false;

        return ({
          ...state,
          [id]: { ...theme },
        });
      }

      case ARCHIVE_TOPIC: {
        const topicId = action.id;
        const themes = state;
        const selectedThemes = getThemesByTopic({ entities: { themes } }, topicId, false, true);
        Object.keys(selectedThemes).forEach((key) => {
          const { id } = selectedThemes[key];
          themes[id].archived = true;
        });

        return themes;
      }

      case UNARCHIVE_TOPIC: {
        const topicId = action.id;
        const themes = state;
        const selectedThemes = getThemesByTopic({ entities: { themes } }, topicId, false, true);
        Object.keys(selectedThemes).forEach((key) => {
          const { id } = selectedThemes[key];
          themes[id].archived = false;
        });

        return themes;
      }

      case MOVE_THEME: {
        const {
          id, indexChange, topicId, prevTopicId,
        } = action;
        let themes = state;
        themes[id].topicId = topicId;
        const startingPosition = themes[id].position;
        let neighborTheme;

        if (indexChange > 0) { // moving down
          neighborTheme = find(sortBy(themes, 'position'), (theme) => (theme.position > startingPosition) && ((theme.position - startingPosition) >= indexChange));
          if (neighborTheme) {
            themes[id].position = neighborTheme.position + 0.5; // ensures that our theme gets behind the next one
          }
        } else { // moving up
          neighborTheme = findLast(sortBy(themes, 'position'), (theme) => (theme.position < startingPosition) && ((theme.position - startingPosition) <= indexChange));
          if (neighborTheme) {
            themes[id].position = neighborTheme.position - 0.5; // ensures that our theme gets in front of the previous one
          }
        }

        themes = reindex(themes, 'topicId', prevTopicId);
        themes = reindex(themes, 'topicId', topicId);

        return themes;
      }

      case NEW_THEME: {
        const { topicId } = action;
        const id = getRandomInt();
        let themes = state;
        themes[id] = {
          id,
          topicId,
          position: 0.5,
          archived: false,
        };

        themes = reindex(themes, 'topicId', topicId);

        return themes;
      }

      case SAVE_COURSE_SUCCEEDED: {
        const themes = state;
        const persistedThemeKeys = Object.keys(themes).filter(key => (
          themes[key].id > 0 && themes[key].destroy !== true
        ));
        const persistedThemes = {};
        persistedThemeKeys.map((key) => { persistedThemes[key] = themes[key]; return key; });

        return persistedThemes;
      }

      case UPDATE_THEME_TITLE: {
        const { id, title } = action;
        const theme = state[id];
        theme.title = title;

        return ({
          ...state,
          [id]: theme,
        });
      }

      case TOGGLE_THEME_ACCESS: {
        const { id, nextValue } = action;
        const theme = state[id];
        theme.freeAccess = nextValue;

        return ({
          ...state,
          [id]: theme,
        });
      }

      case TOGGLE_TOPIC_ACCESS: {
        const { id: topicId, nextValue } = action;
        const nextState = mapValues(state, (theme) => {
          if (theme.topicId === topicId) {
            return { ...theme, freeAccess: nextValue };
          }
          return theme;
        });

        return nextState;
      }

      default:
        if (action.entities && action.entities.themes) {
          return merge({}, state, action.entities.themes);
        }
        return state;
    }
  },

  lessons: (state = {}, action) => {
    switch (action.type) {
      case ADD_LESSON_FROM_MASTER_LESSON: {
        const {
          newId, index, themeId, topicId, masterLessonId, masterLesson,
        } = action;
        let lessons = state;
        const newLesson = {
          position: index,
          id: newId,
          archived: false,
          themeId,
          topicId,
          masterLessonId,
          masterLesson,
        };

        lessons = {
          ...lessons,
          [newId]: newLesson,
        };
        lessons = reindex(lessons, 'themeId', themeId);

        return lessons;
      }

      case COPY_LESSON: {
        const {
          id, newId, index, themeId, topicId,
        } = action;
        let lessons = state;
        const newLesson = {
          ...lessons[id],
          position: index,
          id: newId,
          themeId,
          topicId,
        };

        lessons = {
          ...lessons,
          [newId]: newLesson,
        };
        lessons = reindex(lessons, 'themeId', themeId);

        return lessons;
      }

      case COPY_THEME: {
        const { id, newId, topicId } = action;
        const lessons = state;
        const selectedLessons = getLessonsByTheme({ entities: { lessons } }, id);
        const newLessons = {};
        Object.keys(selectedLessons).map((key) => {
          const newLessonId = getRandomInt();
          newLessons[newLessonId] = JSON.parse(JSON.stringify(selectedLessons[key]));
          newLessons[newLessonId].id = newLessonId;
          newLessons[newLessonId].themeId = newId;
          newLessons[newLessonId].topicId = topicId;
          return newLessons[newLessonId];
        });

        return {
          ...lessons,
          ...newLessons,
        };
      }

      case COPY_TOPIC: {
        const { newThemesStartId, sourceThemeIds, newId } = action;
        const lessons = state;
        const newLessons = {};
        sourceThemeIds.map((sourceThemeId, themeIndex) => {
          const selectedLessons = getLessonsByTheme({ entities: { lessons } }, sourceThemeId);
          Object.keys(selectedLessons).map((sourceLessonId) => {
            const newLessonId = getRandomInt();
            newLessons[newLessonId] = JSON.parse(JSON.stringify(selectedLessons[sourceLessonId]));
            newLessons[newLessonId].id = newLessonId;
            newLessons[newLessonId].themeId = newThemesStartId + themeIndex;
            newLessons[newLessonId].topicId = newId;
            return newLessons[newLessonId];
          });
          return selectedLessons;
        });

        return {
          ...lessons,
          ...newLessons,
        };
      }

      case ARCHIVE_LESSON: {
        const { id } = action;
        const lesson = state[id];
        lesson.archived = true;

        return ({
          ...state,
          [id]: { ...lesson },
        });
      }

      case UNARCHIVE_LESSON: {
        const { id } = action;
        const lesson = state[id];
        lesson.archived = false;

        return ({
          ...state,
          [id]: { ...lesson },
        });
      }

      case ARCHIVE_THEME: {
        const themeId = action.id;
        const lessons = state;
        const selectedLessons = getLessonsByTheme({ entities: { lessons } }, themeId);
        Object.keys(selectedLessons).forEach((key) => {
          const { id } = selectedLessons[key];
          lessons[id].archived = true;
        });

        return lessons;
      }

      case UNARCHIVE_THEME: {
        const themeId = action.id;
        const lessons = state;
        const selectedLessons = getLessonsByTheme({ entities: { lessons } }, themeId, false, true);
        Object.keys(selectedLessons).forEach((key) => {
          const { id } = selectedLessons[key];
          lessons[id].archived = false;
        });

        return lessons;
      }

      case ARCHIVE_TOPIC: {
        const topicId = action.id;
        const lessons = state;
        const selectedLessons = getLessonsByTopic({ entities: { lessons } }, topicId);
        Object.keys(selectedLessons).forEach((key) => {
          const { id } = selectedLessons[key];
          lessons[id].archived = true;
        });

        return lessons;
      }

      case UNARCHIVE_TOPIC: {
        const topicId = action.id;
        const lessons = state;
        const selectedLessons = getLessonsByTopic({ entities: { lessons } }, topicId, false, true);
        Object.keys(selectedLessons).forEach((key) => {
          const { id } = selectedLessons[key];
          lessons[id].archived = false;
        });

        return lessons;
      }

      case MOVE_LESSON: {
        const {
          id, index, themeId, prevThemeId,
        } = action;
        let lessons = state;
        if (themeId === prevThemeId) {
          lessons[id] = {
            ...lessons[id],
            position: index,
          };
        } else {
          const newLessonId = getRandomInt();
          lessons[newLessonId] = {
            ...lessons[id],
            id: newLessonId,
            themeId,
            position: index,
          };
          lessons[id].destroy = true;
        }

        lessons = reindex(lessons, 'themeId', prevThemeId);
        lessons = reindex(lessons, 'themeId', themeId);

        return lessons;
      }

      case SAVE_COURSE_SUCCEEDED: {
        const lessons = state;
        const persistedLessonKeys = Object.keys(lessons).filter(key => (
          lessons[key].id > 0 && lessons[key].destroy !== true
        ));
        const persistedLessons = {};
        persistedLessonKeys.map((key) => { persistedLessons[key] = lessons[key]; return key; });

        return persistedLessons;
      }

      case TOGGLE_LESSON_ACCESS: {
        const { id, nextValue } = action;
        const lesson = state[id];
        lesson.freeAccess = nextValue;

        return ({
          ...state,
          [id]: lesson,
        });
      }

      case TOGGLE_THEME_ACCESS: {
        const { id: themeId, nextValue } = action;
        const nextState = mapValues(state, (lesson) => {
          if (lesson.themeId === themeId) {
            return { ...lesson, freeAccess: nextValue };
          }
          return lesson;
        });

        return nextState;
      }

      case TOGGLE_TOPIC_ACCESS: {
        const { id: topicId, nextValue } = action;
        const nextState = mapValues(state, (lesson) => {
          if (lesson.topicId === topicId) {
            return { ...lesson, freeAccess: nextValue };
          }
          return lesson;
        });

        return nextState;
      }

      default:
        if (action.entities && action.entities.lessons) {
          return merge({}, state, action.entities.lessons);
        }
        return state;
    }
  },

  masterLessons: (state = {}, action) => {
    switch (action.type) {
      case UPDATE_MASTER_LESSON_TITLE: {
        const { id, title } = action;
        const masterLesson = state[id];
        masterLesson.title = title;

        return ({
          ...state,
          [id]: masterLesson,
        });
      }

      default:
        if (action.entities && action.entities.masterLessons) {
          return merge({}, state, action.entities.masterLessons);
        }
        return state;
    }
  },
};

const reducers = {
  isAdmin: (state = false, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },

  isSearchingForMasterLessons: (state = false, action) => {
    switch (action.type) {
      case FINISH_SEARCH_FOR_MASTER_LESSONS:
        return false;

      case START_SEARCH_FOR_MASTER_LESSONS:
        return true;

      default:
        return state;
    }
  },

  archiveLesson: (state = false) => state, // TODO

  unarchiveLesson: (state = false) => state, // TODO

  deleteLesson: (state = false) => state, // TODO

  matchingMasterLessonIds: (state = [], action) => {
    switch (action.type) {
      case FINISH_SEARCH_FOR_MASTER_LESSONS:
        return action.matchingMasterLessonIds;

      default:
        return state;
    }
  },

  sourceCourseId: (state = 0, action) => {
    switch (action.type) {
      case SET_SOURCE_COURSE:
        return action.courseId;

      default:
        return state;
    }
  },

  sourceCourseIsLoading: (state = false, action) => {
    switch (action.type) {
      case FINISH_LOADING_COURSE:
        return false;

      case START_LOADING_COURSE:
        return true;

      default:
        return state;
    }
  },

  targetCourseId: (state = 0, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
};

export const entities = (combineReducers({
  courses: entityReducers.courses,
  topics: entityReducers.topics,
  themes: entityReducers.themes,
  lessons: entityReducers.lessons,
  masterLessons: entityReducers.masterLessons,
}));

export default reducers;
