// 
// @flow
import React from 'react';
import { filter, includes, reject } from 'lodash';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import MultiSelectorContainer from './MultiSelectorContainer';
import CheckboxList from '../custom-components/CheckboxList';
import SearchInput from '../custom-components/input/SearchInput';



class AddExistingResourcesContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      availableResources: props.resourceAvailableItem,
      selectorType: null,
      searchValue: '',
    };
  }

  handleSelectorTypeChange = (val) => {
    this.setState({ selectorType: val.value, availableResources: this.props.resourceAvailableItem, searchValue: '' });
  };

  handleItemsSelect = (items) => {
    let itemsToSet = items.map((item) => (item.value));
    const allItems = this.props.entityAvailableItem;
    const allResources = this.props.resourceAvailableItem;
    const selectedItemsIds = items && items.map((item) => item.value);
    if (selectedItemsIds) {
      const selectedItems = filter(allItems, item => selectedItemsIds.includes(item.id));
      const filteredResourcesIds = selectedItems.map((item) => item.resourcesIds);
      const resourcesConcatinated = [].concat(...filteredResourcesIds);
      const filteredResources = filter(allResources, res => resourcesConcatinated.includes(res.id));
      itemsToSet = filteredResources.map((res) => ({ id: res.id, title: res.title, coursesIds: res.coursesIds }));
    }
    if (items.length === 0) this.setState({ availableResources: allResources });
    else this.setState({ availableResources: itemsToSet });
  };

  handleChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  render() {
    const {
      entityAvailableItem, inputVariableNameForEntities, inputVariableNameForResources,
    } = this.props;

    const { selectorType, searchValue } = this.state;

    const entityValue = inputVariableNameForEntities.split('_')[0] === 'courses' ? 'Course' : 'Master lesson';
    const options = [
      { value: 'entity', label: entityValue },
      { value: 'all_resources', label: 'All resources' },
    ];

    const selectedValue = options.find(s => s.value === selectorType);
    const currentEntityId = window.location.pathname.match(/(\d+)/)[0];
    const allItemsWithoutCurrent = reject(entityAvailableItem, item => item.id === parseInt(currentEntityId, 10));
    let allResourcesWithoutExisting = reject(this.state.availableResources, res => includes(res.coursesIds || res.masterLessonIds, parseInt(currentEntityId, 10)));

    if (searchValue !== '') {
      allResourcesWithoutExisting = filter(allResourcesWithoutExisting, doc => new RegExp(searchValue, 'i').test(doc.title + doc.id));
    }

    return (
      <div>
        <Row>
          <Col xs="12" className="mb-1">
            <p>Search by:</p>
            <Select
              isSearchable
              options={options}
              name='search_by'
              onChange={this.handleSelectorTypeChange}
              value={selectedValue}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            {
              (() => {
                if (selectorType === 'entity') {
                  return (
                    <>
                      <MultiSelectorContainer // Entities
                        itemsAvailable={allItemsWithoutCurrent}
                        inputVariableName={inputVariableNameForEntities}
                        previouslySelectedItemsIds={[]}
                        change={this.handleItemsSelect}
                      />
                      <p className='text-muted font-italic'>Note: You can only choose {entityValue}s with attached resources</p>
                    </>
                  );
                } else if (selectorType === 'all_resources') {
                  return (
                    <div className="search-courses-wrapper">
                      <SearchInput
                        hidePrependIcon
                        onChange={e => this.handleChange(e)}
                        value={searchValue}
                        onFocus={() => {}}
                        onBlur={() => {}}
                        placeholder="Search for a resource..."
                        resetSearchFilter={() => this.setState({ searchValue: '' })}
                        isSearching={!!searchValue}
                      />
                    </div>
                  );
                }
              })()
            }
          </Col>
        </Row>
        {
          selectorType ? (
            <Row>
              <Col xs="12">
                <CheckboxList
                  items={allResourcesWithoutExisting}
                  inputVariableName={inputVariableNameForResources}
                />
              </Col>
            </Row>
          ) : null
        }
      </div>
    );
  }
}
export default (AddExistingResourcesContainer);
