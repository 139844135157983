// 
// @flow
import {
  combineReducers, applyMiddleware, compose,
  createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import appReducer from '../ducks/reducers/admin';
import saga from '../sagas/index';


const configureStore = (props) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */

  const store = createStore(
    combineReducers({
      ...appReducer,
    }),
    props,
    composeEnhancers(
      applyMiddleware(...middleware),
    ),
  );

  sagaMiddleware.run(saga);
  return store;
};

export default configureStore;
