// 
// @flow
import React from 'react';
import { filter, uniqBy, orderBy } from 'lodash';
import MultiSelectorContainer from './MultiSelectorContainer';



class UniversitySpecialisationNestedSelectorContainer extends React.Component {
  constructor(props) {
    super();
    this.state = {
      availableSpecialisations: props.specialisationsAvailable,
      validSpecialisations: props.previouslySelectedSpecialisationsIds ? this.convertPreviouslySelectedIdsToObjects(props.previouslySelectedSpecialisationsIds, props.specialisationsAvailable) : [],
    };
  }

  convertPreviouslySelectedIdsToObjects = (ids, allSpecialisations) => {
    const filteredSpecialisations = filter(allSpecialisations, spec => ids.includes(spec.id.toString()));
    return filteredSpecialisations.map((spec) => ({ value: spec.id, label: spec.title, order: spec.order }));
  };

  handleUniversitySelect = (universities) => {
    const allSpecialisations = this.props.specialisationsAvailable;
    const allUniversities = this.props.universitiesAvailable;
    const selectedUniversitiesIds = universities && universities.map((uni) => uni.value);
    let specialisationsToSet = allSpecialisations;
    if (selectedUniversitiesIds) {
      const selectedUniversities = filter(allUniversities, uni => selectedUniversitiesIds.includes(uni.id));
      const filteredSpecialisations = selectedUniversities.map((uni) => uni.specialisations);
      const specialisationsConcatinated = [].concat(...filteredSpecialisations);
      const specialisationsUniq = uniqBy(specialisationsConcatinated, (spec) => spec.id);
      specialisationsToSet = specialisationsUniq.map((spec) => ({ id: spec.id, title: spec.name, order: spec.order }));
    }
    this.setState({ availableSpecialisations: specialisationsToSet });
    this.handleSpecialisationSelect(this.state.validSpecialisations, '', specialisationsToSet);
  };

  handleSpecialisationSelect = (specialisations, key, availableSpecialisationsFromUniversity) => {
    let availableSpecialisations = [];
    if (availableSpecialisationsFromUniversity) {
      availableSpecialisations = availableSpecialisationsFromUniversity;
    } else {
      availableSpecialisations = this.state.availableSpecialisations;
    }
    const availableSpecialisationsIds = availableSpecialisations.map(spec => spec.id);
    if (specialisations) {
      const selectedSpecialisations = filter(specialisations, spec => availableSpecialisationsIds.includes(spec.value));
      this.setState({ validSpecialisations: selectedSpecialisations });
    } else if (key === 'handlingSpecialisationsSelect') {
      this.setState({ validSpecialisations: specialisations });
    }
  };

  render() {
    const {
      inputVariableNameForUniversity, universitiesAvailable, previouslySelectedUniversitiesIds,
      previouslySelectedSpecialisationsIds, inputVariableNameForSpecialisation,
    } = this.props;

    const { availableSpecialisations } = this.state;
    const orderedUniversities = orderBy(universitiesAvailable, 'order', 'asc');
    const orderedSpecialisations = orderBy(availableSpecialisations, 'order', 'asc');

    return (
      <>
        <p>University (no selection is considered applied to all)</p>
        <MultiSelectorContainer // Universities
          itemsAvailable={orderedUniversities}
          inputVariableName={inputVariableNameForUniversity}
          previouslySelectedItemsIds={previouslySelectedUniversitiesIds}
          change={this.handleUniversitySelect}
        />
        <p className="pt-4">Specialisation (no selection is considered applied to all)</p>
        <MultiSelectorContainer // Specialiastions
          itemsAvailable={orderedSpecialisations}
          inputVariableName={inputVariableNameForSpecialisation}
          previouslySelectedItemsIds={previouslySelectedSpecialisationsIds}
          change={(selectedSpecialisations) => this.handleSpecialisationSelect(selectedSpecialisations, 'handlingSpecialisationsSelect')}
          value={this.state.validSpecialisations}
        />
      </>
    );
  }
}
export default (UniversitySpecialisationNestedSelectorContainer);
