// 
// @flow
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { toDate, parseISO } from 'date-fns';
import nb from 'date-fns/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import InputWithButton from '../scenes/shared/InputWithButton';

registerLocale('nb', nb);



class DatePickerContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      startDate: this.props.startDate ? toDate(parseISO(this.props.startDate)) : null,
    };
  }

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  render() {
    return (
      <DatePicker
        customInput={<InputWithButton name={this.props.name} />}
        locale="nb"
        selected={this.state.startDate}
        onChange={this.handleChange}
        dateFormat="d.MM.yyyy"
        popperPlacement="bottom-end"
        {...(this.props)}
      />
    );
  }
}

export default (DatePickerContainer);
