// 
// @flow
import React from 'react';



class ActivityAttributeChangeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  // Helper function to convert values to appropriate string formats
  formatValue = (val) => {
    if (val === '' || val == null) {
      return '-'; // If empty string or null/undefined
    } else if (typeof val === 'boolean') {
      return val ? 'True' : 'False'; // Convert boolean to "True" or "False"
    } else {
      return val;
    }
  };

  renderedAttributes = (data) => Object.entries(data).map(([key, value]) => {
    const title = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize first letter of the title
    const oldVal = this.formatValue(value[0]);
    const newVal = this.formatValue(value[1]);

    if (oldVal === newVal) {
      return null;
    }

    return (
      <div key={key}>
        <h3>{title}</h3>
        <p><strong>Old:</strong> {oldVal}</p>
        <p><strong>New:</strong> {newVal}</p>
        <hr />
      </div>
    );
  });

  render() {
    const { selectedLog } = this.props;
    const { attributeChanges } = this.props.selectedLog;
    return (
      <div className="modal-overlay">
        <div className="modal-dialog" style={{ minWidth: '600px !important' }}>
          <div className="modal-content" style={{ width: '100%' }}>
            <div className="activity-attribute-change-modal-wrapper">
              <div className="modal-header">
                <h3 className="modal-title">
                  {selectedLog.subjectType === 'Course' ? 'Settings' : selectedLog.humanDescription + ` (${selectedLog.relatedId})`}
                </h3>
                <div className="close" onClick={() => this.handleCancel()}>
                  <span>&times;</span>
                </div>
              </div>
              <div className='modal-body'>
                <div className='row'>
                  <div className="col-xl-12">
                    {this.renderedAttributes(attributeChanges)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleCancel = () => {
    const { closeModal } = this.props;
    closeModal();
  };
}

export default (ActivityAttributeChangeModal);
