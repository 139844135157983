// 
// @flow
import React from 'react';
import { map } from 'lodash';


class EditMasterLessonModal extends React.Component {
  render() {
    const {
      associatedCourses, onConfirmationModalOpen, onEditModalClose, currentCourseId,
    } = this.props;

    return (
      <div className="modal-dialog" style={{ minWidth: '600px !important' }}>
        <div className="modal-content" style={{ width: '100%' }}>
          <div className="modal-header">
            <h3 className="modal-title">
              Edit master lesson
            </h3>
            <div className="close" onClick={onEditModalClose}>
              <span>&times;</span>
            </div>
          </div>
          <div className='modal-body'>
            <div className='row'>
              {((Object.keys(associatedCourses)).length > 1)
                ? (
                  <>
                    <div className="col-xl-12">
                      <p>This master lesson is shared between the following courses:</p>
                      <ul className='modal-scrollable-list'>
                        { map(associatedCourses, ((course, key) => (key === currentCourseId.toString() ? (<li key={key}>{course} (current)</li>) : (<li key={key}>{course}</li>)))) }
                      </ul>
                      <p style={{ marginTop: '10px' }}>If you want to edit the master lesson for all courses, click “Edit for all courses”.</p>
                      <p>
                        If you only want to edit the master lesson for { map(associatedCourses, ((course, key) => (key === currentCourseId.toString() ? course : ''))) },
                        but not for the other courses, click “Edit for this course only”.
                      </p>
                      <p className='text-muted font-italic'>
                        Note: Choosing “Edit for this course only” duplicates the master lesson and this new master lesson will no longer be shared
                        with the other courses in the list above. This action is irreversible.
                      </p>
                    </div>
                    <div className="col-xl-12 d-flex justify-content-around">
                      <a
                        href={this.props.editMasterUrl}
                        className="btn btn-xs btn-success"
                        data-turbolinks="false"
                      >
                        Edit for all courses
                      </a>
                      <div
                        onClick={onConfirmationModalOpen}
                        className="btn btn-xs btn-secondary"
                      >
                        Edit for this course only
                      </div>
                    </div>
                  </>
                )
                : (
                  <>
                    <div className="col-xl-12">
                      <p>Editing this master lesson will affect only this course.</p>
                    </div>
                    <div className="col-xl-12 d-flex justify-content-around">
                      <a
                        href={this.props.editMasterUrl}
                        className="btn btn-xs btn-success"
                        data-turbolinks="false"
                      >
                        Edit master lesson
                      </a>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (EditMasterLessonModal);
