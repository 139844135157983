// 
// @flow
import React from 'react';
import Select from 'react-select';


const MultiSelectorContainer = (props) => {
  const itemsAvailable = props.itemsAvailable.map(item => ({ value: item.id, label: item.title }));
  const previouslySelectedItems = props.previouslySelectedItemsIds ? itemsAvailable.filter(item => props.previouslySelectedItemsIds.includes(item.value.toString())) : null;
  let allProps = {
    isMulti: true,
    isClearable: true,
    isSearchable: true,
    isDisabled: props.isDisabled,
    options: itemsAvailable,
    defaultValue: previouslySelectedItems, // if, due to create subscriptions failure, form was rerendered
    name: props.inputVariableName,
    delimiter: ',',
  };

  if (props.change) {
    allProps = {
      ...allProps,
      onChange: props.change,
    };
  }

  if (props.value) {
    allProps = {
      ...allProps,
      value: props.value,
    };
  }

  return (
    <Select
      {...allProps}
    />
  );
};

export default MultiSelectorContainer;
