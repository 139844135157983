// 
// @flow
import {
  filter, isEmpty,
  merge, sortBy, last, first,
} from 'lodash';

import { selectors as coursesSlectors } from './courses';
import { selectors as topicsSelectors } from './topics';
import { selectors as themesSelectors } from './themes';
import { selectors as lessonSelectors } from './scenes/lesson';

export const types = {
  GET_LESSON: 'LESSONS/GET_LESSON',
  GET_LESSON_FAILURE: 'LESSONS/GET_LESSON_FAILURE',
  GET_LESSON_SUCCESS: 'LESSONS/GET_LESSON_SUCCESS',
  SET_LESSON_PROGRESS_FAILURE: 'LESSONS/SET_LESSON_PROGRESS_FAILURE',
  SET_LESSON_PROGRESS_SUCCESS: 'LESSONS/SET_LESSON_PROGRESS_SUCCESS',
};

export default (state = {}, action) => {
  if (action.entities && action.entities.lessons) {
    return merge({}, state, action.entities.lessons);
  }

  return state;
};

export const actions = {
  getLesson: (id, parentId, courseId) => ({
    type: types.GET_LESSON, id, parentId, courseId,
  }),
};

const getLesson = (state, id) => {
  if (id === 'intro') {
    return {
      id: 'intro',
      themeId: null,
      freeAccess: true,
      archived: false,
    };
  }
  if (id === 'outro') {
    return {
      id: 'outro',
      themeId: null,
      freeAccess: true,
      archived: false,
    };
  }
  return state.entities.lessons[id];
};

const getLessonsByTheme = (state, themeId) => sortBy(filter(state.entities.lessons, { themeId }), lesson => lesson.position);

// Simple selectors
export const selectors = {
  getLesson,
  getLessons: (state, themeId) => (
    sortBy(filter(state.entities.lessons, { themeId }), lesson => lesson.position)
  ),
  getLessonsByTheme,
  getLessonsByTopic: (state, topicId) => {
    const themes = filter(state.entities.themes, { topicId });
    if (isEmpty(themes)) return undefined;
    let lessons = [];
    Object.keys(themes).map((key) => {
      lessons = lessons.concat(selectors.getLessons(state, themes[key].id));
      return lessons;
    });
    return lessons;
  },
  getLessonsByCourse: (state, courseId) => {
    const topics = filter(state.entities.topics, { courseId });
    if (isEmpty(topics)) return undefined;
    let lessons = [];
    Object.keys(topics).map((key) => {
      lessons = lessons.concat(selectors.getLessonsByTopic(state, topics[key].id));
      return lessons;
    });
    return lessons;
  },
  getLessonWithSameTheme: (state, id) => {
    const lesson = selectors.getLesson(state, id);
    if (lesson === undefined) return undefined;
    return filter(state.entities.lessons, { themeId: lesson.themeId });
  },
  getNextThemeByLesson: (state, currentLessonId) => {
    const currentTheme = themesSelectors.getThemeByLesson(state, currentLessonId);
    if (currentTheme === undefined) return undefined;
    const lesson = getLesson(state, currentLessonId);
    if (lesson === undefined) return undefined;
    const themes = themesSelectors.getThemesByLesson(state, currentLessonId);
    if (isEmpty(themes)) return undefined;
    if (lessonSelectors.isFreeTopicSelected(state)) {
      const course = coursesSlectors.getCourseByLesson(state, currentLessonId);
      if (course) {
        const freeThemes = themesSelectors.getThemesWithFreeAccess(state, course.id);
        const currentThemeIndex = freeThemes.findIndex(theme => theme.id === currentTheme.id);
        return freeThemes[currentThemeIndex + 1];
      }
    }
    const currentThemeIndex = themes.findIndex(theme => theme.id === currentTheme.id);
    if (currentThemeIndex < 0) return undefined;
    const nextTheme = themes[currentThemeIndex + 1];
    if (nextTheme === undefined) {
      const nextTopic = topicsSelectors.getNextTopic(state, currentTheme.topicId);
      if (nextTopic === undefined) return undefined;
      const nextTopicThemes = themesSelectors.getThemes(state, nextTopic.id);
      return nextTopicThemes[0];
    }
    return nextTheme;
  },
  getFirstLessonInNextTheme: (state, currentLessonId) => {
    const currentLesson = selectors.getLesson(state, currentLessonId);
    if (currentLesson === undefined) return undefined;
    const nextTheme = themesSelectors.getNextTheme(state, currentLesson.themeId);
    if (nextTheme === undefined) return undefined;
    return selectors.getLessons(state, nextTheme.id)[0];
  },
  getLastFreeLessonInTheme: (state, themeId) => {
    const lessons = getLessonsByTheme(state, themeId);
    const freeLessons = filter(lessons, { freeAccess: true });
    const lastFree = last(freeLessons);
    return lastFree;
  },
  getFirstFreeLessonInFirstFreeTheme: (state, urlSlug) => {
    const course = coursesSlectors.getCourseFromUrlSlug(state, urlSlug);
    const courseId = course && course.id;
    const themes = themesSelectors.getThemesWithFreeAccess(state, courseId);
    if (themes.length > 0) {
      const firstTheme = themes[0];
      const lessons = getLessonsByTheme(state, firstTheme.id);
      return first(lessons);
    }
    return null;
  },
  isAnyContentFree: (state) => {
    const freeLessons = filter(state.entities.lessons, { freeAccess: true });
    if (state.entities.lessons) return freeLessons.length > 0;
  },
};
