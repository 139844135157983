// 
// @flow
import React from 'react';
import Spinner from 'react-spinkit';


export const DefaultSpinner = (props) => (
  <div className="d-flex justify-content-center m-5">
    <Spinner name="ball-clip-rotate-multiple" fadeIn={props.preventFadeIn && 'none'} />
  </div>
);

export default DefaultSpinner;
